import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './page/home/Home';
import Blog from './page/blog/Blog';
import Company from './page/company/Company';
import Product_Catalog from './page/productcatalog/Product_Catalog';
import Cart from './page/cart/Cart';
import ProductDetail from './page/productdetail/ProductDetail';
import CheckOut from './page/checkout/CheckOut';
import SelectAddress from './page/selectaddress/SelectAddress';
import OurProducts from './page/ourproducts/OurProducts';
import Sustainability from './page/sustainability/Sustainability';
import Contactus from './page/contact/Contactus';
import PetFood from './page/petfood/PetFood';
import Login from './page/login/Login';
import Register from './page/register/Register';
import BackOffice from './page/backoffice/BackOffice';
import LoginBackOffice from './page/loginBackoffice/LoginBackOffice';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  { path: '/login', element: <Login /> },
  { path: '/loginbackoffice', element: <LoginBackOffice /> },
  { path: '/register', element: <Register /> },
  { path: '/backoffice', element: <BackOffice /> },

  {
    element: <App />,
    children: [
      { path: "/", element: <Home /> },
      { path: '/blog', element: <Blog /> },
      { path: '/company', element: <Company /> },
      { path: '/productcatalog', element: <Product_Catalog /> },
      { path: '/cart', element: <Cart /> },
      { path: '/product/:id', element: <ProductDetail /> },
      { path: '/checkout', element: <CheckOut /> },
      { path: '/selectaddress', element: <SelectAddress /> },
      { path: '/ourproducts', element: <OurProducts /> },
      { path: '/sustainability', element: <Sustainability /> },
      { path: '/contact', element: <Contactus /> },
      { path: '/petfood/:id', element: <PetFood /> },
      
    ],
  },

]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
