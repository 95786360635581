import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from '@mui/material'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useRef } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "18px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
function Category(props:any) {
    const cookies = new Cookies(null, {path:'/'});
    const nameCategoryRef = useRef<any>(null);

    const addCategory = async () => {
        const nameCategory = nameCategoryRef.current.value;

        if(nameCategory === undefined || nameCategory === '')
        {
            return;
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/categories/add`, {
            name: nameCategory
        }, {
            headers: {
                Authorization: cookies.get('access_token')
            }
        });

        if(data && data.message === "OK")
        {
            Swal.fire({
                icon: 'success',
                title: 'แจ้งเตือน',
                text: `เพิ่มหมวดหมู่ ${nameCategory} เรียบร้อยแล้ว`
            });
            nameCategoryRef.current.value = '';
            return props.getCategories();
        }

        Swal.fire({
            icon: 'error',
            title: 'แจ้งเตือน',
            text: `เพิ่มหมวดหมู่ ${nameCategory} ไม่สำเร็จ (${data.message || "ไม่ทราบสาเหตุ"})`
        });
    }

    const deleteCategory = async (category_id:number) => {
        const index = props.listCategories.findIndex((element:any) => element.id === category_id);
        if(index === -1)
        {
            return;
        }

        Swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือน',
            text: `คุณยืนยันที่จะลบหมวดหมู่ ${props.listCategories[index].name} ใช่หรือไม่ หากคุณกดยืนยัน ข้อมูลสินค้าที่อยู่ในหมวดหมู่นี้ จะถูกลบออกไปทั้งหมดด้วย และ ไม่สามารถกู้คืนได้`,
            showCancelButton: true
        }).then(async (result) => {
            if(!result.isConfirmed)
            {
                return;
            }

            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/categories/delete`, {
                id: category_id
            }, {
                headers: {
                    Authorization: cookies.get('access_token')
                }
            });

            if(data && data.message === 'OK')
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `ลบหมวดหมู่ ${props.listCategories[index].name} เรียบร้อยแล้ว`
                });

                return props.getCategories();
            }

            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `ลบหมวดหมู่ ${props.listCategories[index].name} ไม่สำเร็จ (${data.message || "ไม่ทราบข้อผิดพลาด"})`
            });
        });
    }

    return (
        <Grid container flexDirection={"column"} gap={"24px"}>
            <Text40px>หมวดหมู่ทั้งหมด</Text40px>

            <Grid container component={Paper} padding={"10px"} gap={"24px"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                <Text20px>เพิ่มหมวดหมู่</Text20px>
                <Grid container justifyContent={"space-between"} rowGap={"24px"}>
                    <TextField variant="outlined" sx={{
                        width: { xs: "100%", sm: "50%" }
                    }}
                        required
                        label="ชื่อหมวดหมู่"
                        inputRef={nameCategoryRef}
                    />
                    <Button size="small" sx={{
                        width: { xs: "100%", sm: "20%" }
                    }} variant="contained" onClick={() => addCategory()}>เพิ่ม</Button>
                </Grid>
            </Grid>
            <TableContainer component={Paper} >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ชื่อหมวดหมู่</TableCell>
                            <TableCell align="center">รายการสินค้าในหมวดหมู่</TableCell>
                            <TableCell align="center">จัดการ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            props.listCategories.map((row:any, index:number) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">{row.count}</TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={() => deleteCategory(row.id)}>
                                            <DeleteForeverIcon sx={{ color: "red",cursor:"pointer" }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

export default Category