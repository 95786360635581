import { Grid, Typography, styled } from '@mui/material';
import React from 'react'
import HeroImg from '../../layout/HeroImg';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';

type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px"
  },
  fontWeight: "700",
  color: "#0051AC"

}));
const Text16px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },

}));
const Text18px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "18px"
  },

}));
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px"
  },
}));
const Text24px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700"
}));
const Img = styled("img")(({ theme }) => ({
}));
const Contactuscomponent = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  gap: "32px"
}));
const CardContainer = styled(Grid)(({ theme }) => ({
  width: "90%",
  margin: "0 auto",
  [theme.breakpoints.up("xs")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "space-between",
  },
  rowGap: "16px"
}));
const Cardaddress = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "75%",
  },
  [theme.breakpoints.up("md")]: {
    width: "48%",
  },
  boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
  border: "1px solid rgba(0,0,0,0.1)",
  borderRadius: "6px",
  padding: "24px"
}));
function Contactus({ }: Props) {
  const card = [
    {
      head: "Lorem ipsum dolor sit a  met (Thailand)",
      address: "47 Moo 5 Thakham, Bangpakong, Chachoengsao, 24130 Thailand.",
      phone: "Phone : 033-684233-4",
      directions: "Get directions"
    },
    {
      head: "Lorem ipsum dolor sit a  met (Thailand)",
      address: "47 Moo 5 Thakham, Bangpakong, Chachoengsao, 24130 Thailand.",
      phone: "Phone : 033-684233-4",
      directions: "Get directions"
    },
    {
      head: "Lorem ipsum dolor sit a  met (Thailand)",
      address: "47 Moo 5 Thakham, Bangpakong, Chachoengsao, 24130 Thailand.",
      phone: "Phone : 033-684233-4",
      directions: "Get directions"
    },
    {
      head: "Lorem ipsum dolor sit a  met (Thailand)",
      address: "47 Moo 5 Thakham, Bangpakong, Chachoengsao, 24130 Thailand.",
      phone: "Phone : 033-684233-4",
      directions: "Get directions"
    },
  ]
  return (
    <Contactuscomponent container>
      <HeroImg />
      <Text40px sx={{ textAlign: "center" }}>Integration for growth!</Text40px>
      <CardContainer container>
        {
          card.map((carditem, idx) => (
            <Cardaddress key={idx}>
              <Text24px gutterBottom>{carditem.head}</Text24px>
              <Text20px gutterBottom>{carditem.address}</Text20px>
              <Grid container alignItems={"center"} columnGap={"10px"}>
                <LocalPhoneIcon />
                <Text16px>
                  Phone : 033-684233-4
                </Text16px>
              </Grid>
              <Grid container alignItems={"center"} columnGap={"10px"}>
                <FmdGoodIcon />
                <Text16px>
                  Get directions
                </Text16px>
              </Grid>
            </Cardaddress>
          ))
        }
      </CardContainer>
    </Contactuscomponent>
  )
}

export default Contactus