import { Grid, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
type Props = {}

const LinkMenu = styled(Link)(({ theme }) => ({
    color: "#3892F8",
    fontWeight: "600",
    [theme.breakpoints.up("xs")]: {
        fontSize: "8px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "18px"
    },
}));
const Text16px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "7px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },

}));
const Text24px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
    fontWeight: "700"
}));
const Img = styled("img")(({ theme }) => ({

}));
const Footer = (props: Props) => {
    const [information, setInformation] = useState<any>({});
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/information`);
            setInformation(data.information);
            setLoaded(true);
        })();
    }, []);

    const NavbarLists = [
        {
            path: "/",
            pagename: "Home"
        },

        {
            path: "/company",
            pagename: "Company"
        },
        {
            path: "/ourproducts",
            pagename: "Our products"
        },
        {
            path: "/productcatalog",
            pagename: "Product Catalog"
        },
        {
            path: "/sustainability",
            pagename: "Sustainability"
        },
        {
            path: "/blog",
            pagename: "Blog"
        },
        {
            path: "/contact",
            pagename: "Contact US"
        },
    ]
    const Navigate = useNavigate()

    return (
        loaded ?
            <Grid container padding={"24px 0"}>
                <Grid container width={"95%"} margin={"0 auto"} flexDirection={{ xs: "column-reverse", md: "row" }}>
                    {/* Left */}
                    <Grid width={{ xs: "100%", md: "30%" }} container flexDirection={"column"} rowGap={"12px"}>
                        <Img src={logo} alt="logo" onClick={() => Navigate("/")} sx={{
                            width: { xs: "50px", sm: "70px", md: "80px", lg: "100px" },
                            height: { xs: "50px", sm: "70px", md: "80px", lg: "100px" },
                            cursor: "pointer"
                        }} />
                        <Text24px fontWeight={"700"}>M-TECH PRODUCT CO.,LTD.</Text24px>
                        {/* <Text16px >EASTERN SEABOARD INDUSTRIAL ESTATE
                            64/91, 64/92, 64/93 Moo 4,
                            T. PLUAKDAENG, A. PLUAKDAENG, RAYONG 21140,
                            THAILAND.</Text16px> */}
                            <Text16px>
                                {
                                    information.about
                                }
                            </Text16px>
                        <Text16px>E-mail : {information.email}</Text16px>
                        <Text16px>Phone : {
                            information.phone_number.split(",").map((element:any, index:number) => {
                                return (
                                    <>
                                        {element} { (information.phone_number.split(",").length - 1) !== index && ", " }
                                    </>
                                )
                            })    
                        }</Text16px>

                    </Grid>
                    {/* Rigth */}
                    <Grid container width={{ xs: "100%", md: "70%" }} alignItems={"center"} padding={{ xs: "0 0 16px 0", md: "0" }} justifyContent={{ xs: "center", md: "end" }} columnGap={"12px"}>
                        {
                            NavbarLists.map((Navbar, idx) => (
                                <LinkMenu key={idx} to={Navbar.path}>{Navbar.pagename}</LinkMenu>
                            ))
                        }
                    </Grid>

                </Grid>
                <Grid container width={"95%"} margin={"0 auto"}>
                    <Text16px>2024 © M-TECH PRODUCT All Rights Reserved.</Text16px>
                </Grid>
            </Grid>
        : null
    )
}

export default Footer