import { Typography, styled, Grid, Button, Card, CardActions, CardContent, CardMedia } from '@mui/material';
import React from 'react'
import HeroImg from '../../layout/HeroImg';
import plastic from '../../assets/passtic.png'
import plastic1 from '../../assets/passticglass.png'
import prod from '../../assets/product.jpg'
import operations from '../../assets/Operations.jpg'
import SocietyandPeople from '../../assets/SocietyandPeople.jpg'
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LanguageIcon from '@mui/icons-material/Language';
type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px"
  },
  fontWeight: "700",
  color: "#0051AC"

}));
const Text16px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },

}));
const Text18px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "18px"
  },

}));
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "18px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px"
  },
}));
const Text24px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700"
}));
const Img = styled("img")(({ theme }) => ({
}));
const Sustainabilitycomponent = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  gap: "32px"
}));
const Content = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  gap: "16px",
  width: "90%",
  margin: "0 auto"
}));
const LeftContent = styled(Grid)(({ theme }) => ({
}));
const RightContent = styled(Grid)(({ theme }) => ({
  gap: "12px"

}));
const SustainabilityStrategy = styled(Grid)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#F9F9F9",
  padding: "24px 0",
  flexDirection: "column",
  gap: "24px"
}));
const Cardcontainer = styled(Grid)(({ theme }) => ({
  gap: "16px",
  justifyContent: "center",

}));
const Circle = styled(Grid)(({ theme }) => ({
  borderRadius: "50%",
  width: "70px",
  height: "70px",
  position: "absolute",
  top: "0%",
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: "#FFF",
  alignItems: "center",
  justifyContent: "center"

}));
function Sustainability({ }: Props) {
  const card = [
    {
      photo: prod,
      icon: <HomeIcon sx={{ fontSize: "42px", color: "#3892F8" }} />,
      nameitem: "Materials or Products"
    },
    {
      photo: operations,
      icon: <FavoriteIcon sx={{ fontSize: "42px", color: "#3892F8" }} />,
      nameitem: "Operations"
    },
    {
      photo: SocietyandPeople,
      icon: <LanguageIcon sx={{ fontSize: "42px", color: "#3892F8" }} />,
      nameitem: "Society and People"
    },
  ]
  return (
    <Sustainabilitycomponent container>
      <HeroImg />
      <Grid sx={{
        textAlign: "center",
        width: { xs: "90%", md: "50%" },
        margin: "0 auto",
      }}>
        <Text40px sx={{ color: "#000" }} gutterBottom>
          This is what we care
        </Text40px>
        <Text18px>
          Today’s food ecosystem faces serious challenges. More than 25 percent of global greenhouse gas emissions comes from food systems and one-third of food produced is wasted. For the world to achieve sustainability, immediate action on the right management of the food systems is urgently needed.
        </Text18px>
      </Grid>
      <Content container>
        <LeftContent sx={{ width: { xs: "100%", md: "48%" } }}>
          <Text24px>
            <b>M Tech Food Packaging Products</b>
          </Text24px>
          <Text16px>
            Our company prioritizes cleanliness, safety and environmental care. We utilize precise and effcient machinery to produce high demand products for the food packaging industry.
            <br /><br />
            Let's take a look at the process of development Initially the client sends a drawing of specifications for a product and then the basic procurement process begins with a request for quotation.
            <br /><br />
            The team then analyzes whether the project is feasible with our manufacturing process. If the project is feasible the team then creates a blueprint to assess what materials and manufacturing processes are needed to accommodate the client.
            <br /><br />
            M-Tech has specialized in the thermoforming vacuum process for plastic trays used in automotive and electronic parts but recently witched to food packaging. In making this transition we have acquired GMP certification and enclosed our production process within a standard
          </Text16px>
        </LeftContent>
        <RightContent sx={{ width: { xs: "100%", md: "48%" } }}>
          <Img src={plastic} sx={{ width: "100%" }} alt="plastic" />
          <Grid container sx={{ gap: "1%" }}>
            <Img sx={{ width: "49%" }} src={plastic1} alt="plastic" />
            <Img sx={{ width: "49%" }} src={plastic1} alt="plastic" />
          </Grid>
        </RightContent>
      </Content>
      <SustainabilityStrategy container>
        <Grid sx={{
          textAlign: "center",
          width: { xs: "90%", md: "50%" },
          margin: "0 auto",
        }}>
          <Text40px sx={{ color: "#000" }} gutterBottom>
            Sustainability Strategy
          </Text40px>
          <Text18px gutterBottom>
            o us, sustainability means considering our impact throughout the entire value chain. We have always been serious about minimizing the impacts of our operations and products on the environment while maximizing the positive impacts on our stakeholders, consumers, and society.
          </Text18px>
          <Text18px>
            <b>
              We work pro-actively on becoming a leader in all three sustainability pillars – products, operations, and society.
            </b>
          </Text18px>
        </Grid>
        <Cardcontainer container>
          {
            card.map((item) => (
              <Card sx={{ width: { xs: "50%", sm: "30%" } }}>
                <CardMedia
                  sx={{ height: { xs: "150px", sm: "200px", md: "300px" } }}
                  image={item.photo}
                  title={item.nameitem}
                />
                <CardContent sx={{ backgroundColor: "#3892F8", height: "100%", position: "relative" }}>
                  <Circle container>
                    {item.icon}
                  </Circle>
                  <Text24px sx={{ color: "#FFF", paddingTop: "25px", textAlign: "center" }}>
                    {item.nameitem}
                  </Text24px>
                </CardContent>
              </Card>
            ))
          }
        </Cardcontainer>
      </SustainabilityStrategy>
    </Sustainabilitycomponent>
  )
}

export default Sustainability