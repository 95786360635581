import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Modal, Paper, Radio, RadioGroup, TextField, Typography, styled } from '@mui/material';
import React from 'react'
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const Text24px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
    fontWeight: "700"
}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
    fontWeight: "700"
}));
const SelectAddess = styled(Grid)(({ theme }) => ({

}));
const Text16px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },

}));
const CheckBox = styled(RadioGroup)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",

}));
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: '#FFF',
    border: '2px solid #DDE0E5',
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
};
const Submit = styled(Button)(({ theme }) => ({
    backgroundColor: "#1E293B",
    border: "1px solid #1E293B",
    color: "#FFF",
    borderRadius: "6px",
    WebkitTapHighlightColor: "#FFF",
    '&:hover': {
        backgroundColor: "#FFF",
        color: "#1E293B"
    },
    fontSize: "16px",
    padding: "6px"
}));
const Back = styled(Button)(({ theme }) => ({
    backgroundColor: "#3892F8",
    border: "1px solid #3892F8",
    color: "#FFF",
    borderRadius: "6px",
    WebkitTapHighlightColor: "#FFF",
    '&:hover': {
        backgroundColor: "#FFF",
        color: "#3892F8"
    },
    fontSize: "16px",
    fontWeight:"700",
    padding: "6px 24px"
}));
function SelectAddress({ }: Props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const Navigate = useNavigate()
    return (
        <Grid sx={{ backgroundColor: "#F9F9F9", padding: "24px 0" }}>
            <Grid width={"90%"} margin={"16px auto"}>
                <Text40px>เลือกที่อยู่จัดส่ง</Text40px>
            </Grid>
            <Grid sx={{ width: "90%", margin: "0 auto", flexDirection: "column" }} container component={Paper}>
                <FormControl>
                    <CheckBox sx={{ padding: { xs: "20px", sm: "32px", md: "40px" } }}>
                        <FormControlLabel sx={{ columnGap: "16px" }} value="1" control={<Radio />} label={
                            <Grid>
                                <Grid container>
                                    <FmdGoodIcon /><Text20px sx={{ color: "#656572" }}>สถานที่จัดส่ง</Text20px>
                                </Grid>
                                <Grid>
                                    <Text16px>18 True Tower, Ratchadaphisek Road, Huai Khwang, Ban gkok 10310, Thailand</Text16px>
                                </Grid>
                            </Grid>
                        } />
                        <FormControlLabel sx={{ columnGap: "16px" }} value="2" control={<Radio />} label={
                            <Grid>
                                <Grid container>
                                    <FmdGoodIcon /><Text20px sx={{ color: "#656572" }}>สถานที่จัดส่ง</Text20px>
                                </Grid>
                                <Grid>
                                    <Text16px>18 True Tower, Ratchadaphisek Road, Huai Khwang, Ban gkok 10310, Thailand</Text16px>
                                </Grid>
                            </Grid>
                        } />
                    </CheckBox>
                </FormControl>
                <Divider />
                <Grid container sx={{ padding: { xs: "20px", sm: "32px", md: "40px" } }} justifyContent={"center"} alignItems={"center"}>
                    <IconButton onClick={handleOpen}>
                        <AddCircleOutlineIcon sx={{ fontSize: "32px" }} />
                    </IconButton>
                    <Text20px>เพิ่มที่อยู่จัดส่ง</Text20px>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Text24px>เพิ่มที่อยู่จัดส่ง</Text24px>
                    <Grid container sx={{ flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", gap: "16px" }}>
                        <TextField label="ชื่อ-นามสกุล" variant="outlined" sx={{ width: { xs: "100%", md: "48%" } }} />
                        <TextField label="ที่อยู่จัดส่ง" variant="outlined" sx={{ width: { xs: "100%", md: "48%" } }} />
                    </Grid>
                    <Grid container sx={{ flexDirection: { xs: "column", md: "row" }, justifyContent: "space-between", gap: "16px" }}>
                        <TextField label="เบอร์โทรศัพท์" variant="outlined" sx={{ width: { xs: "100%", md: "48%" } }} />
                        <TextField label="อื่นๆเพิ่มเติม" variant="outlined" sx={{ width: { xs: "100%", md: "48%" } }} />
                    </Grid>
                    <Grid container justifyContent={"center"}>
                        <Submit variant="contained" sx={{ width: { xs: "100%", md: "48%" } }}>เพิ่ม</Submit>
                    </Grid>
                </Box>
            </Modal>
            <Grid width={"90%"} margin={"16px auto"}>
                <Back variant="contained" onClick={()=>Navigate("/checkout")}>กลับ</Back>
            </Grid>
        </Grid>
    )
}

export default SelectAddress