import { EditOutlined } from '@mui/icons-material'
import { Button, FormControl, FormLabel, Grid, TextField } from '@mui/material'
import { useRef, useState, useEffect } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

type Props = {}

function ConfigWeb({ }: Props) {
    const cookies = new Cookies(null, {path:'/'});
    const [websiteInfoUseState, setWebsiteInfoUseState] = useState<any>({});
    const [loaded, setLoaded] = useState<boolean>(false);

    const websiteNameRef = useRef<any>(null);
    const titleRef = useRef<any>(null);
    const descriptionRef = useRef<any>(null);
    const emailRef = useRef<any>(null);
    const phoneRef = useRef<any>(null);
    const facebookRef = useRef<any>(null);
    const youtubeRef = useRef<any>(null);
    const aboutRef = useRef<any>(null);
    const addressRef = useRef<any>(null);
    const mapRef = useRef<any>(null);

    useEffect(() => {
        (async () => {
            await getConfig();
            setLoaded(true);
        })();
    }, []);

    const handleUpdateConfig = async () => {
        const websiteName = websiteNameRef.current.value;
        const title = titleRef.current.value;
        const description = descriptionRef.current.value;
        const email = emailRef.current.value;
        const phone = phoneRef.current.value;
        const facebook = facebookRef.current.value;
        const youtube = youtubeRef.current.value;
        const about = aboutRef.current.value;
        const address = addressRef.current.value;
        const map = mapRef.current.value;

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/information/update`, {
            name: websiteName,
            title: title,
            description: description,
            email: email,
            phone_number: phone,
            facebook: facebook,
            youtube: youtube,
            line: '',
            about: about,
            address: address,
            map_url: map
        }, {
            headers: {
                Authorization: cookies.get('access_token')
            }
        });

        if(data && data.message === "OK")
        {
            return Swal.fire({
                icon: 'success',
                title: 'แจ้งเตือน',
                text: 'อัพเดทข้อมูลเรียบร้อยแล้ว'
            }).then(() => {
                window.location.reload();
            });
        }

        Swal.fire({
            icon: 'error',
            title: 'แจ้งเตือน',
            text: `อัพเดทข้อมูลไม่สำเร็จ (${data.message || "ไม่ทราบสาเหตุ"})`
        })
    }

    const getConfig = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/information`);
        
        if(data.message === 'OK')
        {
            setWebsiteInfoUseState(data.information);
            return true;
        }

        return false;
    }

    return (
        loaded ? 
            <Grid container spacing={2} >
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>ชื่อเว็บไซต์</FormLabel>
                        <TextField

                            id="standard-bare"
                            variant="outlined"
                            placeholder="ชื่อเว็บไซต์"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.name}
                            inputRef={websiteNameRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>หัวเว็บของเว็บไซต์ (Title)</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="หัวเว็บของเว็บไซต์ (Title)"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.title}
                            inputRef={titleRef}
                        />
                    </FormControl>

                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>คำอธิบายของเว็บไซต์</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="คำอธิบายของเว็บไซต์"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.description}
                            inputRef={descriptionRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>อีเมล</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="อีเมล"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.email}
                            inputRef={emailRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>เบอร์โทรศัพท์</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="เบอร์โทรศัพท์"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.phone_number}
                            inputRef={phoneRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>Facebook Link</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Facebook Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.facebook}
                            inputRef={facebookRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>Youtube Link</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Youtube Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.youtube}
                            inputRef={youtubeRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={12} md={12}>
                    <FormControl fullWidth>
                        <FormLabel>เกี่ยวกับเรา</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Youtube Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.about}
                            inputRef={aboutRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>ที่อยู่</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="ที่อยู่"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.address}
                            inputRef={addressRef}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={6} md={6}>
                    <FormControl fullWidth>
                        <FormLabel>Map Link</FormLabel>
                        <TextField
                            id="standard-bare"
                            variant="outlined"
                            placeholder="Map Link"
                            InputProps={{
                                sx: {
                                    height: '45px',
                                }
                            }}
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    py: '8px',
                                    fontSize: '14px',
                                },
                            }}
                            defaultValue={websiteInfoUseState.map_url}
                            inputRef={mapRef}
                        />
                    </FormControl>
                </Grid>



                <Grid item sm={12} xs={12} md={12}>
                    <Button fullWidth variant={'contained'} onClick={() => {
                        handleUpdateConfig();
                    }}><EditOutlined fontSize={'small'} ></EditOutlined> อัพเดต</Button>
                </Grid>

            </Grid>
        : null
    )
}

export default ConfigWeb