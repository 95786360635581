import { Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography, styled } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import plastic from '../../assets/passticglass.png'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Zoom, Navigation, Pagination, Autoplay } from 'swiper/modules';
type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const Text16px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },
}));
const Text14px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "8px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "14px"
    },
}));
const Text24px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
    fontWeight: "700"
}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
    fontWeight: "700"
}));
const LeftContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "90%",
        margin: "0 auto",
        flexDirection: "row",
        justifyContent: "space-between"

    },
    [theme.breakpoints.up("md")]: {
        width: "49%",
        flexDirection: "column",
        justifyContent: "start"

    },
    rowGap: "16px"
}));
const RightContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "90%",
        margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
        width: "49%",
    },
    flexDirection: "column",
    gap: "10px"
}));
const Product = styled(Grid)(({ theme }) => ({
    width: "100%",
    justifyContent: "center",
    marginBottom:"24px"
}));
const Detail = styled(Grid)(({ theme }) => ({
    justifyContent: "space-between",
    [theme.breakpoints.up("xs")]: {
        flexDirection: "column",
        width: "100%",

    },
    [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        width: "90%",

    },
    rowGap: "24px"

}));
const Img = styled("img")(({ theme }) => ({

}));
const BottonAddtoCart = styled(Button)(({ theme }) => ({
    backgroundColor: "#1E293B",
    border: "1px solid #1E293B",
    color: "#FFF",
    borderRadius: "6px",
    WebkitTapHighlightColor: "#FFF",
    '&:hover': {
        backgroundColor: "#FFF",
        color: "#1E293B"
    },
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },
    padding: "6px"
}));
const RelatedProduct = styled(Grid)(({ theme }) => ({
    width: "90%",
    margin:"0 auto",
    rowGap:"16px"
}));
const CardContent = styled(Grid)(({ theme }) => ({
    backgroundColor: "#FFF",
    border: "1px solid #D9D9D9",
    padding: "6px",
    flexDirection: "column",
    gap: "12px",
    // [theme.breakpoints.up("xs")]: {
    //     width: "49%",
    // },
    // [theme.breakpoints.up("sm")]: {
    //     width: "32%",
    // },
    // [theme.breakpoints.up("md")]: {
    //     width: "24%",
    // },
    margin: "0.5%"
}));
const BottonAddtoCartRelated = styled(Button)(({ theme }) => ({
    backgroundColor: "#3892F8",
    border: "1px solid #3892F8",
    color: "#FFF",
    borderRadius: "6px",
    WebkitTapHighlightColor: "#FFF",
    '&:hover': {
      backgroundColor: "#FFF",
      color: "#3892F8"
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px"
    },
    padding: "6px"
  }));
function ProductDetail({ }: Props) {
    const cookies = new Cookies(null, {path:'/'});
    const loopcard = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const Navigate = useNavigate();
    const [product, setProduct] = useState<any>({});
    const [lowPrice, setLowPrice] = useState<string>('0.00');
    const [productPrice, setProductPrice] = useState<any>([]);
    const [productImage, setProductImage] = useState<any>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const selectedPriceRef = useRef<any>(null);

    const { id } = useParams();

    useEffect(() => {
        reloadProduct();
    }, []);

    const reloadProduct = async () => {
        // console.log(`product id: ${id}`)
        const {data} = await axios.get(`${process.env.REACT_APP_API_URL}/products/id/${id}`);
        // console.log(data)
        if(data.message === 'FOUND')
        {
            setLowPrice(data.lowPrice.price);
            setProductPrice(data.priceOfProduct);
            setProductImage(data.imageOfProduct);
            setProduct(data.product[0]);
            setLoaded(true);
        }
    }

    const addToCart = async () => {
        const productCurrentPriceId = selectedPriceRef.current.value;
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/products/addtocart`, {
            product_id: product.id,
            product_price_id: productCurrentPriceId,
            quantity: 1
        }, {
            headers: {
                Authorization: cookies.get('access_token') || null
            }
        });

        if(data.message && data.message === 'success')
        {
            Navigate('/cart');
        }
        else
        {
            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: data.message === 'Invalid token' ? "กรุณาเข้าสู่ระบบก่อน !" : (data.message || "ไม่ทราบสาเหตุ กรุณาลองใหม่อีกครั้ง")
            })
        }
    }

    return (
        loaded ? <Product container>
            <Text40px padding={"24px"}>Shopping Bag</Text40px>
            <Detail container>
                <LeftContent container>
                    <Grid container sx={{ width: { xs: "49%", md: "100%" } }}>
                        <Swiper
                            zoom={true}
                            navigation={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Zoom, Navigation, Pagination]}
                        >
                            {
                                productImage.map((img:any, index:number) => (
                                    <SwiperSlide key={index}>
                                        <Img src={img.path.replace('{api_path}', process.env.REACT_APP_API_URL)} sx={{ width: "100%", height: "100%" }} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </Grid>
                    <Grid container sx={{ width: { xs: "49%", md: "100%" }, height: { xs: "100%", md: "300px" } }}>
                        <ReactPlayer
                            controls
                            playIcon={<PlayArrowIcon />}
                            url='https://www.youtube.com/watch?v=Ai_2iCUBQ-o&list=RDEMBWUl7QwB7_YaqqpYhMfiAQ&index=27'
                            width={'100%'}
                            height={"100%"}
                        />
                    </Grid>
                </LeftContent>
                <RightContent container>
                    <Text14px sx={{ color: "#99A2A5" }}>
                        SKU #{product.code}
                    </Text14px>
                    <Text24px>
                        {product.name}
                    </Text24px>
                    <Text20px>
                        ฿{parseFloat(lowPrice).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Text20px>
                    <Text14px sx={{ color: "#667479" }}><span style={{ color: "#667479" }}> <b>Size:</b> </span> ?</Text14px>
                    {
                        productPrice.length > 0 && <FormControl sx={{ width: { xs: "30%", md: "15%" } }}>
                            <Select
                                defaultValue={productPrice[0].value}
                                inputRef={selectedPriceRef}
                            >
                                {
                                    productPrice.map((element:any, index:number) => {
                                        return (
                                            <MenuItem value={element.value} key={index}>{element.label}</MenuItem>
                                        )
                                    })
                                }
                                {/* <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem> */}
                            </Select>
                        </FormControl>
                    }
                    


                    <Grid>
                        <BottonAddtoCart onClick={()=> { addToCart() }} variant="contained">Add to cart</BottonAddtoCart>
                    </Grid>

                    <Text20px>
                        รายละเอียดสินค้า  <span style={{ fontWeight: "400" }}>รายละเอียดเพิ่มเติม</span>
                    </Text20px>
                    <hr />
                    <Grid container>
                        <Text20px sx={{ color: "#667479", width: "28%", fontWeight: "500" }}>
                            Product Type
                        </Text20px>
                        <Text16px sx={{ color: "#667479" }}>
                            {product.category_name}
                        </Text16px>
                    </Grid>
                    <hr />
                    <Grid container>
                        <Text20px sx={{ color: "#667479", width: "28%", fontWeight: "500" }}>
                            Diameter (Width)
                        </Text20px>
                        <Text16px sx={{ color: "#667479" }}>
                            {product.width}
                        </Text16px>
                    </Grid>
                    <hr />
                    <Grid container>
                        <Text20px sx={{ color: "#667479", width: "28%", fontWeight: "500" }}>
                            Height
                        </Text20px>
                        <Text16px sx={{ color: "#667479" }}>
                            {product.height}
                        </Text16px>
                    </Grid>
                    <hr />
                    <Grid container>
                        <Text20px sx={{ color: "#667479", width: "28%", fontWeight: "500" }}>
                            Shape
                        </Text20px>
                        <Text16px sx={{ color: "#667479" }}>
                            {product.shape_name}
                        </Text16px>
                    </Grid>
                    <hr />
                    <Grid container>
                        <Text20px sx={{ color: "#667479", width: "28%", fontWeight: "500" }}>
                            Overflow Volume
                        </Text20px>
                        <Text16px sx={{ color: "#667479" }}>
                            {product.overflow_volume}
                        </Text16px>
                    </Grid>
                    <hr />
                    {
                        JSON.parse(product.feature).length > 0 && <Grid container>
                            <Text20px sx={{ color: "#667479", width: "28%", fontWeight: "500" }}>
                                Feature
                            </Text20px>
                            <Text16px sx={{ color: "#667479" }}>
                                {
                                    JSON.parse(product.feature).map((element:any, index:number) => {
                                        return (
                                            <li key={index}>
                                                {element}
                                            </li>
                                        )
                                    })
                                }
                                {/* <li>Available for retort process</li>
                                <li>Available for microwave re-heating</li>
                                <li>Available in materials: Polypropylene(PP)</li>
                                <li>Available in coloured (upon demand)</li>
                                <li>Suitable for direct food contact</li>
                                <li>Available in option for embossed logo</li> */}
                            </Text16px>
                        </Grid>
                    }
                    
                    <hr />
                </RightContent>
            </Detail>
            <RelatedProduct container>
                <Text24px>สินค้าที่เกี่ยวข้อง</Text24px>
                <Swiper
                    breakpoints={{
                        0: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        640: {
                            slidesPerView: 3,
                            spaceBetween: 20

                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 20

                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 20

                        },
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                    loop={true}
                >
                    {
                        loopcard.map((id) => (
                            <SwiperSlide key={id}>
                                <CardContent container>
                                    <Img src={plastic} sx={{ width: "100%", margin: "0 auto" }} />
                                    <Text20px>T01-R 76D 38H 110ML</Text20px>
                                    <Text16px sx={{ color: "#B4B4B4" }}><span style={{ color: "#667479" }}>Size:</span> ขนาดด้านใน ∅ 12.7 x 4.7 cm</Text16px>
                                    <Text24px sx={{ color: "#3892F8" }}>฿107.25</Text24px>
                                    <Grid container>
                                        <BottonAddtoCartRelated
                                            onClick={() => Navigate(`/product/${id}`)}
                                            variant="contained">Add to cart</BottonAddtoCartRelated>
                                    </Grid>
                                </CardContent>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>

            </RelatedProduct>
        </Product > : null
    )
}

export default ProductDetail