import { Button, Checkbox, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from '@mui/material'
import plastic from '../../assets/passticglass.png'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px"
  },
  fontWeight: "700",
  color: "#0051AC"

}));
const Text24px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700"
}));
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px"
  },
  fontWeight: "700"
}));
const CartContainer = styled(Grid)(() => ({
  width: "90%",
  margin: "0 auto",
  rowGap: "16px",
  padding: "16px 0",
  flexDirection: "column"
}));
const Img = styled("img")(() => ({

}));
const ButtonCount = styled(Grid)(() => ({
  width: "60%",
  border: "1px solid #DDDDDD",
  borderRadius: "17px",
  justifyContent: "space-between",
  color: "#717171",
  padding: "5px",
  margin: "0 auto",
  alignItems: "center",
  minWidth:"100px"
}));
const Total = styled(Grid)(() => ({
  padding: "12px 24px",
  justifyContent: "space-between",
  height: '100%',
}));
const SubmitTotal = styled(Button)(({ theme }) => ({
  backgroundColor: "#1E293B",
  border: "1px solid #1E293B",
  color: "#FFF",
  borderRadius: "6px",
  WebkitTapHighlightColor: "#FFF",
  '&:hover': {
    backgroundColor: "#FFF",
    color: "#1E293B"
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },
  padding: "6px"
}));
function Cart({ }: Props) {
  const cookies = new Cookies(null, {path:'/'});
  const Navigate = useNavigate()
  const cartitem = [1, 2];
  const [listCarts, setListCarts] = useState<any>([]);
  const [totals, setTotals] = useState<any>(0);

  useEffect(() => {
    (async () => {
      const token = cookies.get('access_token');
      if(token)
      {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
          headers: {
            Authorization: token
          }
        });

        if(data.status_code !== 200)
        {
          cookies.remove('access_token');
          return Navigate('/login');
        }

        reloadCarts();
      }
    })();
  }, []);

  const reloadCarts = async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/carts/get`, {
      headers: {
        Authorization: cookies.get('access_token')
      }
    });

    if(data.carts)
    {
      setTotals(data.totals)
      setListCarts(data.carts);
    }
  }

  const handleChangeQuantity = async (type:string, product_id:number, product_price_id:number) => {
    const index = listCarts.findIndex((element:any) => element.product_id === product_id && element.product_price_id === product_price_id);
    if(index === -1)
    {
      return;
    }

    let update = false;
    let temp_carts = [];
    temp_carts = [
      ...listCarts
    ];

    if(type === 'plus')
    {
      if((temp_carts[index].quantity + 1) > temp_carts[index].stock)
      {
        return Swal.fire({
          icon: 'error',
          title: 'แจ้งเตือน',
          text: `ไม่สามารถเพิ่มได้ เนื่องจาก Stock มีคงเหลือ ${temp_carts[index].stock} ชิ้น`
        });
      }

      temp_carts[index] = {
        ...temp_carts[index],
        quantity: temp_carts[index].quantity += 1
      }
      update = true;
    }
    else if(type === 'minus')
    {
      if(temp_carts[index].quantity > 1)
      {
        temp_carts[index] = {
          ...temp_carts[index],
          quantity: temp_carts[index].quantity -= 1
        }
        update = true;
      }
    }

    if(update)
    {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/carts/update`, {
        data: JSON.stringify(temp_carts)
      }, {
        headers: {
          Authorization: cookies.get('access_token')
        }
      });
      
      if(data && data.message === 'Success')
      {
        setTotals(data.data.totals);
        setListCarts(temp_carts);
      }
    }
  }

  const handleRemove = async (product_id:number, product_price_id:number) => {
    const index = listCarts.findIndex((element:any) => element.product_id === product_id && element.product_price_id === product_price_id);
    if(index === -1)
    {
      return;
    }

    Swal.fire({
      icon: 'warning',
      title: `แจ้งเตือน`,
      text: `คุณต้องการลบ ${listCarts[index].product_name} (${listCarts[index].product_price_name}) ใช่หรือไม่ ?`,
      showCancelButton: true
    }).then(async (result:any) => {
      if(result.isConfirmed)
      {
        let temp_carts = [];
        temp_carts = [
          ...listCarts.filter((element:any) => {
            if(element.product_id === product_id && element.product_price_id === product_price_id)
            {
              return false;
            }
            else
            {
              return true;
            }
          })
        ];

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/carts/update`, {
          data: JSON.stringify(temp_carts)
        }, {
          headers: {
            Authorization: cookies.get('access_token')
          }
        });

        if(data && data.message === 'Success')
        {
          setTotals(data.data.totals);
          setListCarts(temp_carts);
          Swal.fire({
            icon: 'success',
            title: 'แจ้งเตือน',
            text: 'ลบเรียบร้อยแล้ว'
          })
        }
      }
    })
  }

  return (
    <Grid sx={{ backgroundColor: "#F9F9F9" }}>
      <CartContainer container>
        <Text40px padding={"16px 0"}>SHOPPING CART</Text40px>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Product</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Subtotal</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                listCarts.length > 0 ? listCarts.sort((a:any,b:any) => b.product_id - a.product_id).map((cart:any, index:number) => (
                  <TableRow key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td': { padding: 1 } }}
                  >
                    {/* <TableCell align="center" sx={{ width: "50px" }}>
                      <Checkbox defaultChecked />
                    </TableCell> */}
                    <TableCell align="center">
                      <Img src={cart.product_image.replace("{api_path}", process.env.REACT_APP_API_URL)} sx={{ width: "100px" }} />
                    </TableCell>
                    <TableCell align="center">
                      <Text24px>
                        <Link to={`${process.env.REACT_APP_BASE_URL}/product/${cart.product_id}`} style={{ textDecoration: 'none', color: 'black' }}>
                          {cart.product_name} ({cart.product_price_name})
                        </Link>
                      </Text24px>
                    </TableCell>
                    <TableCell align="center">
                      <Text24px>฿ {parseFloat(cart.price_per_each).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</Text24px>
                    </TableCell>
                    <TableCell align="center">
                      <ButtonCount container>
                        <IconButton onClick={() => { handleChangeQuantity('minus', cart.product_id, cart.product_price_id) }}>
                          <RemoveIcon />
                        </IconButton>
                        <Text20px>{cart.quantity}</Text20px>
                        <IconButton onClick={() => { handleChangeQuantity('plus', cart.product_id, cart.product_price_id) }}>
                          <AddIcon />
                        </IconButton>
                      </ButtonCount>
                      {/* <Button variant="contained"> + 1 -</Button> */}
                    </TableCell>
                    <TableCell align="center">
                      <Text24px>฿ {
                        parseFloat(String(cart.price_per_each * cart.quantity)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                      }</Text24px>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => {
                        handleRemove(cart.product_id, cart.product_price_id)
                      }}>
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )) : <TableRow>
                  <TableCell align="center" colSpan={6}>
                    ไม่มีสินค้าในตะกร้า...
                  </TableCell>
                </TableRow>
              }

            </TableBody>
          </Table>
        </TableContainer>
        <Paper sx={{ alignItems: "center", display: "flex" }}>
          <Total container>
            <Text24px>ยอดรวมทั้งหมด</Text24px>
            <Grid container width={"30%"} flexDirection={"column"}>
              <Text40px textAlign={"end"}>฿ {parseFloat(totals).toLocaleString('en-US',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Text40px>
              <SubmitTotal onClick={() => Navigate("/checkout")} variant="contained">ยืนยัน</SubmitTotal>
            </Grid>
          </Total>
        </Paper>
      </CartContainer>
    </Grid>
  )
}

export default Cart