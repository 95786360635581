import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import AllProduct from './AllProduct';
import AddProduct from './AddProduct';
import Category from './Category';
import EditBaner from './EditBaner';
import ConfigWeb from './ConfigWeb';
import Bill from './Bill';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BackOffice() {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();
    const [listProducts, setListProducts] = React.useState<any>([]);
    const [listCategories, setListCategories] = React.useState<any>([]);
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        (async () => {
            const token = cookies.get('access_token');

            if(!token)
            {
                return navigate('/loginbackoffice');
            }
            
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
                headers: {
                    Authorization: token
                }
            });

            if(!data)
            {
                return navigate('/loginbackoffice');
            }
    
            if(data.status_code !== 200)
            {
                cookies.remove('access_token');
                return navigate('/loginbackoffice');
            }

            if(data.status_code === 200 && data.isAdmin === 0)
            {
                return navigate('/loginbackoffice');
            }

            getProducts();
            getCategories();
        })();
    }, []);

    const getProducts = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/products/all`);
        setListProducts(data);
    }

    const getCategories = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/categories/all`);
        setListCategories(data);
    }

    return (
        <Paper sx={{ margin: "24px 12px" }}>
            <Box sx={{ width: { xs: "100%", md: '95%' }, margin: "0 auto", padding: { xs: '0', md: "12px" } }}>
                <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    width: { xs: "100%", md: '95%' },
                    margin: "0 auto",
                    backgroundColor: "#106EE3",
                    '& button': { color: "#FFF" },
                    '& button.Mui-selected': { color: "#FFF511" },
                }}>
                    <Tabs value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label="สินค้าทั้งหมด" {...a11yProps(0)} />
                        <Tab label="เพิ่มสินค้าใหม่" {...a11yProps(1)} />
                        <Tab label="หมวดหมู่ทั้งหมด" {...a11yProps(2)} />
                        <Tab label="ตั้งค่า Banner" {...a11yProps(3)} />
                        <Tab label="ตั้งค่า Website" {...a11yProps(4)} />
                        {/* <Tab label="พิมพ์ใบปะหน้าพัสดุ" {...a11yProps(5)} /> */}



                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <AllProduct listProducts={listProducts} getProducts={getProducts} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <AddProduct/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <Category listCategories={listCategories} getCategories={getCategories} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <EditBaner/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <ConfigWeb/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <Bill/>
                </CustomTabPanel>
            </Box>
        </Paper>
    );
}