import { Button, Grid, Typography, styled } from '@mui/material';
import React, { ChangeEvent, useState } from 'react'
import logo from '../../assets/logo.png'
type Props = {}
const Img = styled("img")(({ theme }) => ({

}));
const InputFile = styled("input")(({ theme }) => ({

}));
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
function EditBaner({ }: Props) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    return (
        <Grid container sx={{
            width: "100%",
            flexDirection: "column",
            rowGap: "24px"
        }}>
            <Text40px>ตั้งค่า Baner</Text40px>
            <Img sx={{
                width: { xs: "50%", sm: "40%", md: "30%" },
                margin: { xs: "0 auto", sm: "0 auto" }
            }}
                src={selectedFile === null ? logo : URL.createObjectURL(selectedFile)} alt="preview" />

            <InputFile sx={{
                width: "100%"
            }} type="file"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (!event.target.files) return
                    setSelectedFile(event.target.files[0]);
                }} />
            <Button size="large" sx={{
                width: { xs: "100%", sm: "50%" },
                margin:"0 auto"
            }} variant="contained">ยืนยัน</Button>
        </Grid>
    )
}

export default EditBaner