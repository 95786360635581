import { Button, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import hero from '../assets/hero.jpg'
import logo from '../assets/logo.png'

type Props = {}
const Card = styled(Grid)(({ theme }) => ({
  backgroundColor: "#156eddb8",
  position: "absolute",
  borderRadius: "16px",
  right: "0",
  [theme.breakpoints.up("xs")]: {
    width: "100%",
    top: '7%',
    padding: "20px 0",
  },
  [theme.breakpoints.up("sm")]: {
    width: "70%",
    top: "15%",
    padding: "30px 0",
  },
  [theme.breakpoints.up("md")]: {
    width: "55%",
    top: "25%",
    padding: "32px 0",
  }
}));
const CardContainer = styled(Grid)(({ theme }) => ({
  position: "relative",
  margin: "0 auto",
  [theme.breakpoints.up("xs")]: {
    width: "95%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "90%",
  },
  [theme.breakpoints.up("md")]: {
    width: "85%",
  },
  [theme.breakpoints.up("lg")]: {
    width: "80%",
  },
}));
const Logo = styled(Grid)(({ theme }) => ({
  // backgroundColor: "#156EDD",
  position: "absolute",
  bottom: "0",
  right: "0"
}));
const BottonLearnMore = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: theme.palette.primary.main,
  borderRadius: "16px",
  WebkitTapHighlightColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF"
  },
}));
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px"
  },
  fontWeight: "700",

}));
const Text16px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },

}));
const HeroImg = (props: Props) => {
  return (
    <Grid container position={"relative"}>
      <img src={hero} alt="hero" width={"100%"} />
      <Card>
        <CardContainer>
          <Text40px color="#FFF511">
            WELCOME TO M-TECH PRODUCT THAILAND
          </Text40px>
          <Text16px color={"#fff"} padding={"16px 0"}>
            A global leader in the longevity packaging manufacturing industry with a strong business network across Asia Pacific, Eka Global develops and brings to the market innovative products that helps consumers “live a healthier lifestyle” today and tomorrow.
          </Text16px>
          <BottonLearnMore variant="contained">
            Learn more
          </BottonLearnMore>
        </CardContainer>
        <Logo className='element'>
          <img src={logo} alt="logo" style={{ position: "absolute", right: "6px", bottom: "-100px", width: "50px" }} />
        </Logo>
      </Card>
    </Grid>
  )
}

export default HeroImg