import { Button, Grid, Typography, styled } from '@mui/material';
import React from 'react'
import petfood from '../../assets/petfood.png'
import cup from '../../assets/cup.png'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import { Zoom, Navigation, Pagination, Autoplay } from 'swiper/modules';
type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "18px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
}));
const Img = styled("img")(() => ({
}));
const LeftContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "90%",
        margin: "0 auto",
    },
    [theme.breakpoints.up("md")]: {
        width: "49%",
    },
    justifyContent:"center"
}));
const RightContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "90%",
        margin: "0 auto"
    },
    [theme.breakpoints.up("md")]: {
        width: "49%",
    },
    flexDirection: "column",
    gap: "16px",
    justifyContent:"center"
}));
const OverviewButton = styled(Button)(() => ({
    backgroundColor: "#fff",
    color: "#0D59AD",
    borderRadius: "16px",
    WebkitTapHighlightColor: "#0D59AD",
    '&:hover': {
        backgroundColor: "#0D59AD",
        color: "#FFF"
    },
    border: "2px solid #0D59AD"
}));
function PetFood({ }: Props) {
    const loopcard = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    return (
        <Grid container sx={{ width:{xs:"100%",md:"90%"}, margin: "24px auto", flexDirection: "column", gap: "24px" }}>
            <Text40px textAlign={"center"}>PET FOOD</Text40px>
            <Grid container>
                <Img src={petfood} sx={{ width: "100%" }} />
            </Grid>
            <Grid container>
                <LeftContent container>
                    <Grid container sx={{ width:"100%" }}>
                        <Swiper
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            zoom={true}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Zoom, Pagination, Autoplay]}
                        >
                            {
                                loopcard.map((id) => (
                                    <SwiperSlide key={id}>
                                        <Img src={cup} sx={{ width: "100%", height: "100%" }} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </Grid>
                </LeftContent>
                <RightContent container>
                    <Text40px sx={{ textDecoration: "underline", textUnderlineOffset: "8px" }}>Overview</Text40px>
                    <Text20px>
                        You love your pets. We love them too. Pets are people’s greatest friends and they deserve a great treat in return. As manufacturers keep introducing new products and innovations that not only enhance pets’ health, but also enable owners to show their love and bond with their pets, the demand for a larger variety of designs, shapes and sizes of pet food packaging is on the rise.
                    </Text20px>
                    <Text20px>
                        Made with O2S technology, our packaging can effectively maintain color and quality of the products, as well as taste and hygiene throughout product shelf-life. With our highly flexible production technology, we can produce any shape of packaging as required by pet food manufacturers.
                    </Text20px>
                    <OverviewButton variant="contained">
                        become one of our customer
                    </OverviewButton>
                </RightContent>
            </Grid>
        </Grid>
    )
}

export default PetFood