import { Grid, Typography, styled } from '@mui/material'
import React from 'react'
import HeroImg from '../../layout/HeroImg'
import plastic from '../../assets/passtic.png'
import plastic1 from '../../assets/passticglass.png'

type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px"
  },
  fontWeight: "700",
  color: "#0051AC"

}));
const Text16px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "7px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },

}));
const Text24px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700"
}));
const Content = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  gap: "16px",
  width: "90%",
  margin: "0 auto"
}));
const LeftContent = styled(Grid)(({ theme }) => ({
}));
const RightContent = styled(Grid)(({ theme }) => ({
  gap:"12px"

}));
const Img = styled("img")(({ theme }) => ({

}));
function Company({ }: Props) {
  return (
    <Grid container gap={"24px"}>
      <HeroImg />
      <Grid textAlign={"center"} width={"100%"}>
        <Text40px>About Us</Text40px>
        <Text16px>Lorem ipsum dolor sit amet consectetur. A a sed adipiscing eget ullamcorper egestas varius malesuada.</Text16px>
      </Grid>
      <Content container>
        <LeftContent sx={{ width: { xs: "100%", md: "48%" }}}>
          <Text24px>
            <b>M Tech Food Packaging Products</b>
          </Text24px>
          <Text16px>
            Our company prioritizes cleanliness, safety and environmental care. We utilize precise and effcient machinery to produce high demand products for the food packaging industry.
            <br /><br />
            Let's take a look at the process of development Initially the client sends a drawing of specifications for a product and then the basic procurement process begins with a request for quotation.
            <br /><br />
            The team then analyzes whether the project is feasible with our manufacturing process. If the project is feasible the team then creates a blueprint to assess what materials and manufacturing processes are needed to accommodate the client.
            <br /><br />
            M-Tech has specialized in the thermoforming vacuum process for plastic trays used in automotive and electronic parts but recently witched to food packaging. In making this transition we have acquired GMP certification and enclosed our production process within a standard
          </Text16px>
        </LeftContent>
        <RightContent sx={{ width: { xs: "100%", md: "48%" } }}>
          <Img src={plastic} sx={{width:"100%"}} alt="plastic"/>
          <Grid container sx={{gap:"1%"}}>
            <Img sx={{width:"49%"}} src={plastic1} alt="plastic" />
            <Img sx={{width:"49%"}} src={plastic1} alt="plastic" />
          </Grid>
        </RightContent>
      </Content>
    </Grid>
  )
}

export default Company