import { Button, Card, CardContent, CardMedia, Grid, Typography, styled } from '@mui/material';
import React from 'react'
import HeroImg from '../../layout/HeroImg';
import view from '../../assets/view.jpg'
import food from '../../assets/Foodpac.png'
import food2 from '../../assets/food.jpg'

type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px"
  },
  fontWeight: "700",
  color: "#0051AC"

}));
const Text16px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "7px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },

}));
const Text24px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700"
}));
const BlogCard = styled(Grid)(({ theme }) => ({
  padding: "24px 0",
  width: "90%",
  margin: "0 auto",
  justifyContent: "center",
  rowGap: "24px"
}));
const FoodPacking = styled(Grid)(({ theme }) => ({
  position: "relative",

}));
const FoodPackingContent = styled(Grid)(({ theme }) => ({
  position: "absolute",
  right: "0",
  top: "0",
  [theme.breakpoints.up("xs")]: {
    width: "100%",
    backgroundColor: "#0066e5",
    height: "100%"
  },
  [theme.breakpoints.up("sm")]: {
    width: "60%",
    backgroundColor: "transparent"
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));
function Blog({ }: Props) {
  const cardloop = [1, 2, 3,4,5,6]

  return (
    <Grid container gap={"24px"}>
      <HeroImg />
      <BlogCard container>
        <Text40px>Blog</Text40px>
        <Grid container justifyContent={"space-between"} rowGap={"16px"}>
          {
            cardloop.map((id) => (
              <Card key={id} sx={{ width: { xs: "90%", sm: "30%", md: "32%" } }}>
                <CardMedia
                  sx={{ height: { xs: 200, sm: 200, md: 300 } }}
                  image={view}
                  title="view"
                />
                <CardContent>
                  <Text16px gutterBottom sx={{ color: "#535353" }}>04 August 2023</Text16px>
                  <Text24px gutterBottom >
                    ประเภทของพลาสติก
                  </Text24px>
                  <Text16px gutterBottom>
                    การจำแนกประเภทพลาสติก สามารถจำแนกได้หลากหลายวิธี แต่วิธีที่นิยมนำมาใช้ในการแบ่งประเภทพลาสติกทั่วไปก็คือ การแบ่งตามลักษณะเมื่อได้รับความร้อน โดยแบ่งเป็น..
                  </Text16px>
                  <Text16px sx={{ color: "#0D59AD" }}><b>Read more</b></Text16px>

                </CardContent>
              </Card>
            ))
          }
        </Grid>
        {/* <ViewButton variant="contained"> <b>View all</b></ViewButton> */}
      </BlogCard>
      <FoodPacking>
        <img src={food} alt="food" style={{ width: "100%" }} />
        <FoodPackingContent>
          <Text40px sx={{ color: "#FFF" }}>
            M Tech
          </Text40px>
          <Text24px sx={{ color: "#FFF" }}>
            Food Packaging Products
          </Text24px>
          <Text16px sx={{ color: "#FFF" }}>
            Our company prioritizes cleanliness, safety and environmental care. We utilize precise and effcient machinery to produce high demand products for the food packaging industry.
          </Text16px>
          <Text16px sx={{ color: "#FFF" }}>
            Let's take a look at the process of development Initially the client sends a drawing of specifications for a product and then the basic procurement process begins with a request for quotation.
          </Text16px>
          <Text16px sx={{ color: "#FFF" }}>
            The team then analyzes whether the project is feasible with our manufacturing process. If the project is feasible the team then creates a blueprint to assess what materials and manufacturing processes are needed to accommodate the client.
          </Text16px>
          <Text16px sx={{ color: "#FFFF" }}>
            M-Tech has specialized in the thermoforming vacuum process for plastic trays used in automotive and electronic parts but recently witched to food packaging. In making this transition we have acquired GMP certification and enclosed our production process within a standard
          </Text16px>
        </FoodPackingContent>
      </FoodPacking>
      <Grid container padding={"12px 0"}>
        <img src={food2} alt="food" style={{ width: "100%" }} />
      </Grid>
    </Grid>
  )
}

export default Blog