import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { Outlet, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
const defaultTheme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#106EE3",
    },
    secondary: {
      main: "#FFFFF",
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          color: "#106EE3",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: "#106EE3",
        },
      },
    },
  },
});
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Navbar />
      <main>
        <Outlet />
      </main>
      <Footer />
    </ThemeProvider>

  );
}

export default App;
