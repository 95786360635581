import { Box, Button, Grid } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react'
import DescriptionIcon from '@mui/icons-material/Description';
import { DeleteOutline } from '@mui/icons-material';

type Props = {}

function Bill({ }: Props) {
    const [categoriesRef, setCategoriesRef] = useState<any>([]);
    const columns: GridColDef[] = [
        { field: 'firstname', headerName: 'ชื่อ', flex: 1 },
        { field: 'lastname', headerName: 'นามสกุล', flex: 1 },
        { field: 'phone', headerName: 'เบอร์โทรศัพท์', flex: 1 },
        { field: 'address', headerName: 'ที่อยู่การจัดส่ง', flex: 1 },
        { field: 'status_payment', headerName: 'สถานะการชำระเงิน', flex: 1 },
        {
            field: 'print', headerName: 'พิมพ์ใบปะหน้าพัสดุ', flex: 1, renderCell: (r) => {
                return (<Button variant={'contained'} color="success" size={'small'} onClick={() => {
                    // printBill(r.row);
                }}><DescriptionIcon fontSize="small"></DescriptionIcon></Button>)
            }
        },
        {
            field: 'action', headerName: 'ลบรายการ', flex: 1, renderCell: (r) => {
                return (<Button variant={'contained'} color="error" size={'small'} onClick={() => {
                    // deleteCategories(r.row.id);
                }}><DeleteOutline fontSize="small"></DeleteOutline></Button>)
            }
        },
    ];
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>

            </Grid>

            <Grid item xs={12} md={12} >

                <Box sx={{ width: { xs: 200, sm: 200, md: '100%' } }} style={{ overflow: 'auto' }} >
                    <DataGrid
                        sx={{ width: { xs: 400, sm: 400, md: '100%' } }}
                        rows={categoriesRef}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[10, 20, 50, 100]}
                        rowSelection={false}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default Bill