import { Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Paper, Radio, RadioGroup, Typography, styled } from '@mui/material';
import React from 'react'
import plastic from '../../assets/passticglass.png'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import palpal from '../../assets/paypal.png'
import { useNavigate } from 'react-router-dom';
type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const Text24px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
    fontWeight: "700"
}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
    fontWeight: "700"
}));
const Img = styled("img")(() => ({

}));
const CheckOutContainer = styled(Grid)(() => ({
    width: "90%",
    margin: "0 auto",
    rowGap: "16px",
    padding: "16px 0",
    flexDirection: "column"
}));
const SubmitTotal = styled(Button)(({ theme }) => ({
    backgroundColor: "#1E293B",
    border: "1px solid #1E293B",
    color: "#FFF",
    borderRadius: "6px",
    WebkitTapHighlightColor: "#FFF",
    '&:hover': {
        backgroundColor: "#FFF",
        color: "#1E293B"
    },
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },
    padding: "6px 16px"
}));
function CheckOut({ }: Props) {
    const Navigate = useNavigate()
    const listcart = [1, 2]
    return (
        <Grid sx={{ backgroundColor: "#F9F9F9", padding: "24px 0" }}>
            <CheckOutContainer>
                <Text40px padding={"16px 0"}>CHECKOUT</Text40px>
                <Grid container flexDirection={"column"} gap={"16px"} component={Paper} padding={"24px"}>
                    <Text24px>
                        ยอดรวมทั้งหมด
                    </Text24px>
                    <Text24px>
                        Product
                    </Text24px>
                    {
                        listcart.map(() => (
                            <Grid container>
                                <Img src={plastic} sx={{ width: "140px" }} />
                                <Grid marginLeft={"24px"} sx={{ width: "30%" }}>
                                    <Text24px>
                                        MT-R4S
                                    </Text24px>
                                    <Text24px sx={{ color: "#252525" }}>
                                        X 1
                                    </Text24px>
                                    <Text24px sx={{ color: "#252525" }}>
                                        ฿ 65.00
                                    </Text24px>
                                </Grid>
                            </Grid>
                        ))
                    }

                    <Text24px>รายการจัดส่ง</Text24px>
                    <Grid container columnGap={"16px"}>
                        <AccessTimeIcon sx={{ fontSize: { xs: "24px", md: "34px" } }} />
                        <Grid>
                            <Text20px>ระยะเวลาจัดส่ง</Text20px>
                            <Text24px>1 - 2 Days </Text24px>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={"space-between"}>
                        <Grid container sx={{ width: "80%" }} columnGap={"16px"}>
                            <FmdGoodIcon sx={{ fontSize: { xs: "24px", md: "34px" } }} />
                            <Grid width={"80%"}>
                                <Text20px>สถานที่จัดส่ง</Text20px>
                                <Text24px>18 True Tower, Ratchadaphisek Road, Huai Khwang, Ban gkok 10310, Thailand </Text24px>
                            </Grid>
                        </Grid>
                        <Grid alignItems={"center"} justifyContent={"end"} container width={"19%"}>
                            <IconButton onClick={()=>Navigate("/selectaddress")}>
                                <ArrowForwardIosIcon sx={{ fontSize: { xs: "24px", md: "34px" } }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <hr />
                    <Text24px>วิธีการชำระเงิน</Text24px>
                    <Grid container>
                        <FormControl>
                            <FormLabel>
                                <Text20px>หมายเลขบัตรเครดิต/เดบิต</Text20px>
                            </FormLabel>
                            <RadioGroup row>
                                <FormControlLabel value="credit" control={<Radio />} label={<CreditScoreIcon sx={{ fontSize: "45px" }} />} />
                                <FormControlLabel value="paypal" control={<Radio />} label={<Img src={palpal} sx={{ width: "100px" }} />} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

            </CheckOutContainer>
            <Grid container alignItems={"end"} width={"90%"} margin={"0 auto"} flexDirection={"column"} component={Paper} padding={"24px"}>
                <Text24px>รวมทั้งสิ้น</Text24px>
                <Text24px>฿ 65</Text24px>
                <SubmitTotal>สั่งซื้อสินค้า</SubmitTotal>
            </Grid>
        </Grid>
    )
}

export default CheckOut