import { Card, CardContent, CardMedia, Grid, Typography, styled } from '@mui/material'
import React from 'react'
import HeroImg from '../../layout/HeroImg'
import view from '../../assets/view.jpg'
import discount from '../../assets/discount.jpg'
import { Link } from 'react-router-dom'
type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const Text16px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },

}));
const Text18px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "18px"
    },

}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "18px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
}));
const LinkContact = styled(Link)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
    fontWeight: "700",
    color:"#FFF",
    textDecoration:"none"
}));
function OurProducts({ }: Props) {
    const cardloop = [1, 2, 3, 4, 5, 6]

    return (
        <Grid container flexDirection={"column"} gap={"24px"}>
            <HeroImg />
            <Text40px textAlign={"center"}>Product <span style={{ color: "#000" }}>Categories</span></Text40px>
            <Text18px textAlign={"center"}>Discover our selection of best-selling products and services</Text18px>
            <Grid container width={"90%"} margin={"0 auto"} justifyContent={"space-between"} rowGap={"16px"}>
                {
                    cardloop.map((id) => (
                        <Card key={id} sx={{ width: { xs: "90%", sm: "30%", md: "32%" } }}>
                            <CardMedia
                                sx={{ height: { xs: 200, sm: 200, md: 300 } }}
                                image={view}
                                title="view"
                            />
                            <CardContent>
                                <Text18px gutterBottom sx={{ color: "#000000", fontWeight: "700" }}>PET FOOD</Text18px>
                                <Text18px gutterBottom >
                                    Lorem ipsum dolor sit amet consectetur. Consectetur a vitae fringilla pulvinar mollis augue quis.
                                </Text18px>
                                <Text16px sx={{ color: "#0D59AD" }}>
                                    <b><Link to={`/petfood/${id}`}>Read more</Link></b>
                                </Text16px>
                            </CardContent>
                        </Card>
                    ))
                }
            </Grid>
            <Grid container sx={{
                width: "100%",
                height: { xs: "180px", sm: "250px", md: "300px", lg: "350px", xl: "400px" },
                backgroundImage: `url("${discount}")`,
                backgroundPosition: "center",
                backgroundSize: "100%",
                backgroundRepeat: "no-repeat",
                position: "relative",
                margin: "5% 0",
                opacity: "70%",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Text40px sx={{ color: "#000" }}>Bring Ekanovation in your bags</Text40px>
                <Grid container sx={{
                    position: "absolute",
                    top: "-15%",
                    width: { xs: "90%", sm: "70%", md: "50%" },
                    backgroundColor: "#FFF",
                    borderRadius: "0 6px 6px 0",
                    boxShadow: "0px 10px 15px -3px #00000040",
                    border: "1px solid #00000040",
                }}>
                    <Grid width={"70%"} sx={{ padding: "12px" }}>
                        <Text20px gutterBottom >
                            <b>Become one of our clients!</b>
                        </Text20px>
                        <Text18px>
                            EASTERN SEABOARD INDUSTRIAL ESTATE
                            64/91, 64/92, 64/93 Moo 4,
                            T. PLUAKDAENG, A. PLUAKDAENG, RAYONG 21140,THAILAND.
                        </Text18px>
                    </Grid>
                    <Grid container sx={{ backgroundColor: "#3892F8", width: "30%", justifyContent: "center", alignItems: "center" }}>
                        <LinkContact to={"/contact"}>CONTACT</LinkContact>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OurProducts