import { Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Typography, styled } from '@mui/material'
import React from 'react'
import logo from '../../assets/logo.png'
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoginIcon from '@mui/icons-material/Login';
import { Link, useNavigate } from 'react-router-dom';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';

type Props = {}
const Img = styled("img")(({ theme }) => ({

}));
const LoginPage = styled(Grid)(({ theme }) => ({
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    margin: "24px auto",
    border: "1px solid #000",
    borderRadius: "32px",
    rowGap: "24px",
    padding: "24px",
    [theme.breakpoints.up("xs")]: {
        width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
        width: "70%",
    },
    [theme.breakpoints.up("md")]: {
        width: "50%",
    },
    maxWidth: "700px",
}));
const LinkText24px = styled(Link)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "24px"
    },
    fontWeight: "700",
    color: "#3892F8",
    textDecoration: "none"

}));
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "36px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "40px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "44px"
    },
    fontWeight: "700",
    color: "#0051AC",

}));
function LoginBackOffice({ }: Props) {
    const cookies = new Cookies(null, { path: '/' });
    const Navigate = useNavigate()
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const usernameRef = React.useRef<any>(null);
    const passwordRef = React.useRef<any>(null);

    React.useEffect(() => {
        (async () => {
          const token = cookies.get('access_token');
          if(token)
          {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
              headers: {
                Authorization: token
              }
            });
    
            if(data && data.status_code === 200 && data.isAdmin === 1)
            {
              return Navigate('/backoffice');
            }
    
            cookies.remove('access_token');
          }
        })();
    }, []);

    const handleLogin = async () => {
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;

        if(username === undefined || username === '' || password === undefined || password === '')
        {
            return Swal.fire({
                title: 'แจ้งเตือน',
                text: 'กรุณากรอกข้อมูลให้ครบทุกช่อง',
                icon: 'error'
            });
        }

        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/members/admin/login`, {
            username,
            password
        });

        if(!data.token)
        {
            return Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: data.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ"
            });
        }

        Swal.fire({
            icon: 'success',
            title: 'แจ้งเตือน',
            text: 'เข้าสู่ระบบเรียบร้อยแล้ว'
          }).then(() => {
            cookies.set('access_token', data.token);
            Navigate('/backoffice');
          })
    }

    return (
        <LoginPage container>
            <Img src={logo} sx={{ width: "30%", padding: "12px 0" }} />
            <Text40px>เข้าสู่ระบบหลังบ้าน</Text40px>
            <FormControl variant="standard" sx={{ width: { xs: "90%", md: "60%" }, '& input': { height: "3rem", fontSize: "1.5rem" } }}>
                <InputLabel htmlFor="user">
                    User Name
                </InputLabel>
                <Input
                    id="user"
                    startAdornment={
                        <InputAdornment position="start">
                            <AccountCircle />
                        </InputAdornment>
                    }
                    inputRef={usernameRef}
                />
            </FormControl>
            <FormControl variant="standard" sx={{ width: { xs: "90%", md: "60%" }, '& input': { height: "3rem", fontSize: "1.5rem" } }}>
                <InputLabel htmlFor="password">
                    Password
                </InputLabel>
                <Input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    startAdornment={
                        <InputAdornment position="start">
                            <LockOpenIcon />
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                    inputRef={passwordRef}
                />
            </FormControl>
            <Grid container justifyContent={"center"} gap={"24px"}>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<LoginIcon />}
                    onClick={() => { handleLogin() }}
                >
                    เข้าสู่ระบบ
                </Button>
                <Button variant="outlined"
                    size="large"
                    startIcon={<HowToRegIcon />}
                    onClick={() => Navigate("/register")}
                >
                    สมัครสมาชิก
                </Button>
            </Grid>
            <LinkText24px to={"/"}>หน้าหลัก</LinkText24px>
        </LoginPage>
    )
}

export default LoginBackOffice