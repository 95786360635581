import { Button, Grid, TextField, Typography, styled } from '@mui/material';
import React, { ChangeEvent, useState } from 'react'

type Props = {}

const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const AddProd = styled(Grid)(({ theme }) => ({
    flexDirection: "column",
    gap: "24px",

}));
const Img = styled("img")(({ theme }) => ({

}));
const InputFile = styled("input")(({ theme }) => ({

}));
function AddProduct({ }: Props) {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    return (
        <AddProd container>
            <Text40px>เพิ่มสินค้าใหม่</Text40px>
            <Grid container flexDirection={{ xs: "column", sm: "row" }} rowGap={"24px"}>
                {/* left */}
                <Grid container sx={{
                    width: { xs: "100%", sm: "40%" },
                    flexDirection: "column", gap: "24px"
                }}>
                    <Img sx={{
                        width: "80%",
                        margin: { xs: "0 auto", sm: "0" }
                    }}
                        src={selectedFile === null ? '' : URL.createObjectURL(selectedFile)} alt="preview" />

                    <InputFile sx={{
                        width: "100%"
                    }} type="file"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            if (!event.target.files) return
                            setSelectedFile(event.target.files[0]);
                        }} />
                </Grid>

                {/* rigth */}
                <Grid container sx={{
                    width: { xs: "100%", sm: "60%" },
                    flexDirection: "column",
                    gap: "24px"
                }}>
                    <TextField sx={{
                        width: { xs: "100%", sm: "50%" }
                    }}
                        required
                        label="ชื่อ"
                    />
                    <TextField sx={{
                        width: { xs: "100%", sm: "50%" }
                    }}
                        required
                        label="ขนาด"
                    />
                    <TextField sx={{
                        width: { xs: "100%", sm: "50%" }
                    }}
                        required
                        label="ราคา"
                    />

                    <Button size="large" sx={{
                        width: { xs: "100%", sm: "50%" }
                    }} variant="contained">ยืนยัน</Button>

                </Grid>
            </Grid>
        </AddProd>
    )
}

export default AddProduct