import { Grid, styled, IconButton } from '@mui/material'
import React, { useState } from 'react'
import logo from "../assets/logo.png"
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Hamburger from 'hamburger-react';
type Props = {}
const LinkMenu = styled(Link)(({ theme }) => ({
    color: "#FFF",
    textDecoration: "none",
    fontWeight: "600",
    [theme.breakpoints.up("xs")]: {
        fontSize: "8px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
}));
const Navbarmobile = styled(Grid)(({ theme }) => ({
    position: "absolute",
    right: "0",
    top: "100%",
    [theme.breakpoints.up("xs")]: {
        display: "flex"
    },
    [theme.breakpoints.up("md")]: {
        display: "none"
    },
    backgroundColor: "#106EE3",
    padding: "12px",
    flexDirection: "column",
    rowGap: "12px",
    zIndex: "5"
}));
const Navbardesktop = styled(Grid)(({ theme }) => ({
    padding: "6px 0",
    width: "95%",
    margin: "0 auto",
    justifyContent: "space-between",
    alignItems: "center"
}));
const Img = styled("img")(({ theme }) => ({

}));
function Navbar({ }: Props) {
    const [isOpen, setOpen] = useState(false)
    const Navigate = useNavigate()
    const NavbarLists = [
        {
            path: "/",
            pagename: "Home"
        },

        {
            path: "/company",
            pagename: "Company"
        },
        {
            path: "/ourproducts",
            pagename: "Our products"
        },
        {
            path: "/productcatalog",
            pagename: "Product Catalog"
        },
        {
            path: "/sustainability",
            pagename: "Sustainability"
        },
        {
            path: "/blog",
            pagename: "Blog"
        },
        {
            path: "/contact",
            pagename: "Contact US"
        },

    ]
    return (
        <Grid container width={"100%"} sx={{ backgroundColor: "#106EE3", position: "relative" }}>
            {/* Navbar desktop */}
            <Navbardesktop container>
                <Img src={logo} alt="logo" onClick={() => Navigate("/")} sx={{
                    width: { xs: "50px", sm: "70px", md: "80px", lg: "100px" },
                    height: { xs: "50px", sm: "70px", md: "80px", lg: "100px" },
                    cursor: "pointer"
                }} />
                <Grid container sx={{ width: { xs: "80%", md: "90%" }, columnGap: { md: "10px", lg: "13px" }, justifyContent: "end", alignItems: "center" }}>
                    {
                        NavbarLists.map((Navbar, idx) => (
                            <LinkMenu key={idx} sx={{ display: { xs: "none", md: "block" } }} to={Navbar.path}>{Navbar.pagename}</LinkMenu>
                        ))
                    }
                    <IconButton sx={{ padding: "0" }} >
                        <SearchIcon sx={{ color: "#fff" }} />
                    </IconButton>
                    <IconButton aria-label="cart" onClick={() => Navigate("/cart")}>
                        <ShoppingCartIcon sx={{ color: "#fff" }} />
                    </IconButton>
                    <IconButton sx={{ padding: "0" }}
                        onClick={() => Navigate("/login")}
                    >
                        <AccountCircleIcon sx={{ color: "#fff" }} />
                    </IconButton>
                    <IconButton sx={{ display: { xs: "block", md: "none" } }}>
                        <Hamburger toggled={isOpen} toggle={setOpen} color='#FFF' />
                    </IconButton>

                </Grid>
            </Navbardesktop>
            {/* Navbar mobile */}
            {
                isOpen ? <>
                    <Navbarmobile>
                        {
                            NavbarLists.map((Navbar, idx) => (
                                <LinkMenu onClick={() => setOpen(!isOpen)} key={idx} to={Navbar.path}>{Navbar.pagename}</LinkMenu>
                            ))
                        }
                    </Navbarmobile>
                </> : <></>
            }
        </Grid>
    )
}

export default Navbar