import { Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Typography, styled } from '@mui/material'
import React from 'react'
import logo from '../../assets/logo.png'
import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoginIcon from '@mui/icons-material/Login';
import { Link, useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import BadgeIcon from '@mui/icons-material/Badge';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie';

type Props = {}
const Img = styled("img")(({ theme }) => ({

}));
const RegisterPage = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  margin: "24px auto",
  border: "1px solid #000",
  borderRadius: "32px",
  rowGap: "24px",
  padding: "24px",
  [theme.breakpoints.up("xs")]: {
    width: "90%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
  maxWidth: "700px",
}));
const LinkText24px = styled(Link)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700",
  color: "#3892F8",
  textDecoration: "none"

}));
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "36px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "40px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "44px"
  },
  fontWeight: "700",
  color: "#0051AC",

}));
function Register({ }: Props) {
  const cookies = new Cookies(null, { path: '/' });
  const Navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = React.useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    (async () => {
      const token = cookies.get('access_token');
      if(token)
      {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/token/check`, {
          headers: {
            Authorization: token
          }
        });

        if(data && data.status_code === 200)
        {
          return Navigate('/');
        }

        cookies.remove('access_token');
      }
    })();
  }, []);

  const fullNameRef = React.useRef<any>(null);
  const usernameRef = React.useRef<any>(null);
  const passwordRef = React.useRef<any>(null);
  const confirmPasswordRef = React.useRef<any>(null);

  const handleRegister = async () => {
    const fullname = fullNameRef.current.value;
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    if(fullname === undefined || fullname === "" || username === undefined || username === "" || password === undefined || password === "" || confirmPassword === undefined || confirmPassword === "")
    {
      return Swal.fire({
        title: 'แจ้งเตือน',
        text: 'กรุณากรอกข้อมูลให้ครบทุกช่อง',
        icon: 'error'
      });
    }

    const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/members/register`, {
      fullname,
      username,
      password,
      passwordConfirm: confirmPassword
    });

    if(!data.token)
    {
      return Swal.fire({
        icon: 'error',
        title: 'แจ้งเตือน',
        text: data.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ"
      });
    }

    Swal.fire({
      icon: 'success',
      title: 'แจ้งเตือน',
      text: 'สมัครสมาชิกเรียบร้อยแล้ว'
    }).then(() => {
      cookies.set('access_token', data.token);
      Navigate('/');
    })
  }

  return (
    <RegisterPage container>
      <Img src={logo} sx={{ width: "30%", padding: "12px 0" }} />
      <Text40px>สมัครสมาชิก</Text40px>
      <FormControl variant="standard" sx={{ width: { xs: "90%", md: "60%" }, '& input': { height: "3rem", fontSize: "1.5rem" } }}>
        <InputLabel htmlFor="name">
          ชื่อ-นามสกุล
        </InputLabel>
        <Input
          id="name"
          startAdornment={
            <InputAdornment position="start">
              <BadgeIcon />
            </InputAdornment>
          }
          inputRef={fullNameRef}
        />
      </FormControl>
      <FormControl variant="standard" sx={{ width: { xs: "90%", md: "60%" }, '& input': { height: "3rem", fontSize: "1.5rem" } }}>
        <InputLabel htmlFor="user">
          User Name
        </InputLabel>
        <Input
          id="user"
          startAdornment={
            <InputAdornment position="start">
              <AccountCircle />
            </InputAdornment>
          }
          inputRef={usernameRef}
        />
      </FormControl>
      <FormControl variant="standard" sx={{ width: { xs: "90%", md: "60%" }, '& input': { height: "3rem", fontSize: "1.5rem" } }}>
        <InputLabel htmlFor="password">
          Password
        </InputLabel>
        <Input
          type={showPassword ? 'text' : 'password'}
          id="password"
          startAdornment={
            <InputAdornment position="start">
              <LockOpenIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputRef={passwordRef}
        />
      </FormControl>
      <FormControl variant="standard" sx={{ width: { xs: "90%", md: "60%" }, '& input': { height: "3rem", fontSize: "1.5rem" } }}>
        <InputLabel htmlFor="confirmpassword">
          Confirm  Password
        </InputLabel>
        <Input
          type={showPassword ? 'text' : 'password'}
          id="confirmpassword"
          startAdornment={
            <InputAdornment position="start">
              <CheckIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword2}
                onMouseDown={handleMouseDownPassword2}
                edge="end"
              >
                {showPassword2 ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputRef={confirmPasswordRef}
        />
      </FormControl>
      <Grid container justifyContent={"center"} gap={"24px"}>
        <Button variant="outlined" size="large" startIcon={<LoginIcon />} onClick={() => handleRegister()}>
          สมัครสมาชิก
        </Button>
      </Grid>
      <LinkText24px to={"/"}>หน้าหลัก</LinkText24px>
    </RegisterPage>
  )
}

export default Register