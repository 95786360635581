import React from 'react'
import HeroImg from '../../layout/HeroImg'
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography, styled } from '@mui/material'
import snack from '../../assets/snack.jpg'
import food from '../../assets/Foodpac.png'
import food2 from '../../assets/food.jpg'
import BGCUP from '../../assets/bg_cup.png'
import cup from '../../assets/cup.png'
import view from '../../assets/view.jpg'
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import wordPng from '../../assets/word.png'
import LanguageIcon from '@mui/icons-material/Language';
type Props = {}
const Text40px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "32px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "40px"
  },
  fontWeight: "700",
  color: "#0051AC"

}));
const Text16px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "7px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },

}));
const Text24px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700"
}));
const ContainerContent = styled(Grid)(({ theme }) => ({
  margin: "24px auto",
  width: "90%",
  flexDirection: "column",
  rowGap: "16px"
}));
const ContainerContentMedia = styled(Grid)(({ theme }) => ({
  rowGap: "16px",
  justifyContent: "space-between"
}));
const World = styled(Grid)(({ theme }) => ({
  borderRadius: "28% 28% 0 0",
  backgroundColor: "#3892F8",
  justifyContent: 'center',
  flexDirection: "column",
  alignItems: "center",
  padding: "24px 0",
  rowGap: "24px"
}));
const Odometer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#FFF",
  color: theme.palette.primary.main,
  borderRadius: "50px",
  [theme.breakpoints.up("xs")]: {
    fontSize: "35px",
    width: "90%",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "55px",
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "65px",
    width: "50%",
  },
  fontWeight: '700',
  padding: "3px 0",

  textAlign: "center"
}));
const FoodPacking = styled(Grid)(({ theme }) => ({
  position: "relative",

}));
const FoodPackingContent = styled(Grid)(({ theme }) => ({
  position: "absolute",
  right: "0",
  top: "0",
  [theme.breakpoints.up("xs")]: {
    width: "100%",
    backgroundColor: "#0066e5",
    height: "100%"
  },
  [theme.breakpoints.up("sm")]: {
    width: "60%",
    backgroundColor: "transparent"
  },
  [theme.breakpoints.up("md")]: {
    width: "50%",
  },
}));
const Economy = styled(Grid)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "center",
  padding: "12px 0"

}));
const Cup = styled(Grid)(({ theme }) => ({
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  height: "100%",
  overflow: "hidden",
  // padding: "24px 0"
}));
const Img = styled("img")(({ theme }) => ({

}));
const CerclcirclePersen = styled(Grid)(({ theme }) => ({
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.up("xs")]: {
    width: "30px",
    height: "30px",
    fontSize: "12px",
    boxShadow: "5px 5px 20px 2px #3892F826",

  },
  [theme.breakpoints.up("sm")]: {
    width: "50px",
    height: "50px",
    fontSize: "16px",
    boxShadow: "5px 5px 20px 12px #3892F826",

  },
  [theme.breakpoints.up("md")]: {
    width: "70px",
    height: "70px",
    fontSize: "20px",
    boxShadow: "5px 5px 20px 12px #3892F826",

  },
  [theme.breakpoints.up("lg")]: {
    width: "100px",
    height: "100px",
    fontSize: "26px",
    boxShadow: "5px 5px 20px 12px #3892F826",

  },
}));
const Persen = styled(Grid)(({ theme }) => ({
  width: "100%",
  color: "#3892F8",
  columnGap: "25px",
  position: "absolute",
  top: "20%",
  left: "90%",
  zIndex: "1",
  backgroundColor: "#FFF"
}));
const Persen1 = styled(Grid)(({ theme }) => ({
  width: "100%",
  color: "#3892F8",
  columnGap: "25px",
  position: "absolute",
  top: "40%",
  left: "90%",
  zIndex: "1",
  backgroundColor: "#FFF"
}));
const Persen2 = styled(Grid)(({ theme }) => ({
  width: "100%",
  color: "#3892F8",
  columnGap: "25px",
  position: "absolute",
  top: "60%",
  left: "90%",
  zIndex: "1",
  backgroundColor: "#FFF"
}));
const Latest = styled(Grid)(({ theme }) => ({
  padding: "24px 0",
  width: "90%",
  margin: "0 auto",
  justifyContent: "center",
  rowGap:"24px"
}));
const ViewButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#0D59AD",
  borderRadius: "16px",
  WebkitTapHighlightColor: "#0D59AD",
  '&:hover': {
    backgroundColor: "#0D59AD",
    color: "#FFF"
  },
  border:"2px solid #0D59AD"
}));
// [theme.breakpoints.up("xs")]: {
// },
// [theme.breakpoints.up("sm")]: {
// },
// [theme.breakpoints.up("md")]: {
// },
// [theme.breakpoints.up("lg")]: {
// },
const Home = (props: Props) => {
  const cardloop = [1, 2, 3]
  return (
    <div>
      <HeroImg />
      <ContainerContent container>
        <Text40px>M Tech Food Packaging Products</Text40px>
        <Text16px>
          We design and develop innovative products based on our insightful understanding of consumers’ rapidly changing lifestyles and the world’s attention to the eOur company prioritizes cleanliness, safety and environmental care. We utilize precise and effcient machinery to produce high demand products for the food packaging industry.
          <br /><br />
          Let's take a look at the process of development Initially the client sends a drawing of specifications for a product and then the basic procurement process begins with a request for quotation.
          <br /><br />
          The team then analyzes whether the project is feasible with our manufacturing process. If the project is feasible the team then creates a blueprint to assess what materials and manufacturing processes are needed to accommodate the client.
          <br /><br />
          M-Tech has specialized in the thermoforming vacuum process for plastic trays used in automotive and electronic parts but recently witched to food packaging. In making this transition we have acquired GMP certification and enclosed our production process within a standardnvironment and sustainability. Produced with state- of-the-art technology and to the highest global standards, our game-changer packaging solutions not only increase the business competitiveness but also, and more importantly, bring greater convenience and a healthier lifestyle to the consumers of today and tomorrow.
        </Text16px>
        <ContainerContentMedia container>
          <Card sx={{ width: { xs: "100%", sm: "49%" } }}>
            <CardMedia
              sx={{ height: { xs: 200, sm: 400, md: 500 } }}
              image={snack}
              title="snack"
            />
            <CardContent>
              <Text24px gutterBottom >
                Lorem ipsum dolor
              </Text24px>
              <Text16px>
                Lorem ipsum dolor sit amet consectetur. Potenti faucibus ornare id donec sit risus urna sem luctus. Libero aliquet sit non.
              </Text16px>
            </CardContent>
            <CardActions>
              {/* <Button size="small">Share</Button>
            <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
          <Grid container sx={{ width: { xs: "100%", sm: "49%", rowGap: "16px" } }}>
            <Grid container>
              <ReactPlayer
                controls
                playIcon={<PlayArrowIcon />}
                url='https://www.youtube.com/watch?v=Ai_2iCUBQ-o&list=RDEMBWUl7QwB7_YaqqpYhMfiAQ&index=27'
                width={'100%'}
                height={"100%"}
              />
            </Grid>
            <Grid container>
              <ReactPlayer
                controls
                playIcon={<PlayArrowIcon />}
                url='https://www.youtube.com/watch?v=Ai_2iCUBQ-o&list=RDEMBWUl7QwB7_YaqqpYhMfiAQ&index=27'
                width={'100%'}
                height={"100%"}
              />
            </Grid>
          </Grid>
        </ContainerContentMedia>
      </ContainerContent>
      <World container>
        <Grid container justifyContent={"center"} columnGap={"6px"}>
          <Text40px sx={{ color: "#000" }}>
            We deliver
          </Text40px>
          <Text40px sx={{ color: "#FFF" }}>
            High
          </Text40px>
          <Text40px sx={{ color: "#000" }}>
            quality products
          </Text40px>
        </Grid>
        <Odometer>
          10,717,754,816
        </Odometer>
        <Text16px sx={{ color: "#FFF" }}>Pcs. / Year</Text16px>
        <img src={wordPng} alt="wordPng" style={{ width: "50%" }} />
        <Grid container sx={{
          justifyContent: "space-around"
        }}>
          <Grid sx={{ color: "#FFF" }}>
            <Text24px>
              We supply <br />
              products to
            </Text24px>
            <Grid container sx={{ alignItems: "center", columnGap: "6px" }}>
              <LanguageIcon />
              <Text24px>3 continents</Text24px>
            </Grid>
          </Grid>
          <Grid sx={{ color: "#FFF" }}>
            <Text24px>
              Our products are
              <br /> distributed in
            </Text24px>
            <Grid container sx={{ alignItems: "center", columnGap: "6px" }}>
              <LanguageIcon />
              <Text24px>3 continents</Text24px>
            </Grid>
          </Grid>
          <Grid sx={{ color: "#FFF" }}>
            <Text24px>
              Worldwide customer
              <br /> shipment to
            </Text24px>
            <Grid container sx={{ alignItems: "center", columnGap: "6px" }}>
              <LanguageIcon />
              <Text24px>3 continents</Text24px>
            </Grid>

          </Grid>

        </Grid>
      </World>
      <FoodPacking>
        <img src={food} alt="food" style={{ width: "100%" }} />
        <FoodPackingContent>
          <Text40px sx={{ color: "#FFF" }}>
            M Tech
          </Text40px>
          <Text24px sx={{ color: "#FFF" }}>
            Food Packaging Products
          </Text24px>
          <Text16px sx={{ color: "#FFF" }}>
            Our company prioritizes cleanliness, safety and environmental care. We utilize precise and effcient machinery to produce high demand products for the food packaging industry.
          </Text16px>
          <Text16px sx={{ color: "#FFF" }}>
            Let's take a look at the process of development Initially the client sends a drawing of specifications for a product and then the basic procurement process begins with a request for quotation.
          </Text16px>
          <Text16px sx={{ color: "#FFF" }}>
            The team then analyzes whether the project is feasible with our manufacturing process. If the project is feasible the team then creates a blueprint to assess what materials and manufacturing processes are needed to accommodate the client.
          </Text16px>
          <Text16px sx={{ color: "#FFFF" }}>
            M-Tech has specialized in the thermoforming vacuum process for plastic trays used in automotive and electronic parts but recently witched to food packaging. In making this transition we have acquired GMP certification and enclosed our production process within a standard
          </Text16px>
        </FoodPackingContent>
      </FoodPacking>
      <Grid container padding={"12px 0"}>
        <img src={food2} alt="food" style={{ width: "100%" }} />
      </Grid>
      <Economy container >
        <Grid container justifyContent={"center"} columnGap={"6px"}>
          <Text24px>The</Text24px>
          <Text24px sx={{ color: "#3892F8" }}>Circular</Text24px>
          <Text24px>Economy</Text24px>
        </Grid>
        <Text16px textAlign={"center"}>
          Lorem ipsum dolor sit amet consectetur. Odio facilisis eu urna adipiscing vitae nec odio egestas. Pulvinar tortor tincidunt dignissim nibh.
        </Text16px>
        <Cup container>
          <Grid sx={{ width: "50%", height: "100%", position: "relative" }}>
            <Img src={BGCUP} alt="bg" sx={{ width: "100%", zIndex: "0" }} />
            <Img src={BGCUP} alt="bg" sx={{ position: "absolute", width: "90%", top: "5%", left: "5%", zIndex: "0" }} />
            <Img src={cup} alt="cup" sx={{
              position: "absolute",
              width: "100%",
              top: "-10%",
              left: "-10%"
            }} />
            <Persen container>
              <CerclcirclePersen container>
                30%
              </CerclcirclePersen>
              <Text24px sx={{ display: { xs: "none", lg: "block" } }}>reduced energy <br /> consumption in last2 <br /> years</Text24px>
            </Persen>
            <Persen1 container>
              <CerclcirclePersen container>
                0%
              </CerclcirclePersen>
              <Text24px sx={{ display: { xs: "none", lg: "block" } }}>waste to landfill</Text24px>
            </Persen1>
            <Persen2 container>
              <CerclcirclePersen container>
                100%
              </CerclcirclePersen>
              <Text24px sx={{ display: { xs: "none", lg: "block" } }}>of our products are <br />recyclable</Text24px>
            </Persen2>
          </Grid>
          <Grid>
            <Text24px sx={{ display: { xs: "block", lg: "none" }, color: "#3892F8" }}>30% reduced energy  consumption in last2  years</Text24px>
            <Text24px sx={{ display: { xs: "block", lg: "none" }, color: "#3892F8" }}>0% waste to landfill</Text24px>
            <Text24px sx={{ display: { xs: "block", lg: "none" }, color: "#3892F8" }}>100% of our products are recyclable</Text24px>
          </Grid>
        </Cup>

      </Economy>
      <Latest container>
        <Text40px sx={{ color: "#000" }}>
          Latest <span style={{ color: "#3892F8" }}>stories</span>
        </Text40px>
        <Grid container justifyContent={"space-between"}>
          {
            cardloop.map((id) => (
              <Card key={id} sx={{ width: { xs: "90%", sm: "30%", md: "32%" } }}>
                <CardMedia
                  sx={{ height: { xs: 200, sm: 200, md: 300 } }}
                  image={view}
                  title="view"
                />
                <CardContent>
                  <Text16px gutterBottom sx={{ color: "#535353" }}>04 August 2023</Text16px>
                  <Text24px gutterBottom >
                    ประเภทของพลาสติก
                  </Text24px>
                  <Text16px gutterBottom>
                    การจำแนกประเภทพลาสติก สามารถจำแนกได้หลากหลายวิธี แต่วิธีที่นิยมนำมาใช้ในการแบ่งประเภทพลาสติกทั่วไปก็คือ การแบ่งตามลักษณะเมื่อได้รับความร้อน โดยแบ่งเป็น..
                  </Text16px>
                  <Text16px sx={{ color: "#0D59AD" }}><b>Read more</b></Text16px>

                </CardContent>
              </Card>
            ))
          }
        </Grid>
        <ViewButton variant="contained"> <b>View all</b></ViewButton>
      </Latest>
    </div>
  )
}

export default Home