import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Modal, TextField, Typography, styled } from '@mui/material';
import React, { ChangeEvent, useState } from 'react'
import product from '../../assets/passticglass.png';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Swal from 'sweetalert2';

type Props = {

}
const Text16px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "16px"
    },
}));
const Text40px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "20px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "32px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "40px"
    },
    fontWeight: "700",
    color: "#0051AC"

}));
const AllProducts = styled(Grid)(({ theme }) => ({
    flexDirection: "column",
    rowGap: "32px"
}));
const Text20px = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        fontSize: "12px"
    },
    [theme.breakpoints.up("sm")]: {
        fontSize: "14px"
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "16px"
    },
    [theme.breakpoints.up("lg")]: {
        fontSize: "20px"
    },
    fontWeight: "700"
}));
const Products = styled(Grid)(({ theme }) => ({
    gap: "24px"
}));
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: "85%", sm: "75%", md: "50%" },
    bgcolor: 'background.paper',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 2
};
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const Img = styled("img")(({ theme }) => ({

}));

function AllProduct(props:any) {
    const cookies = new Cookies(null, {path:'/'});
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [open, setOpen] = React.useState(false);
    const [select, setselect] = React.useState({});
    const handleOpen = (prod: any) => {
        setselect(prod)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);
    const handleRemove = async (product_id:number) => {
        const index = props.listProducts.findIndex((element:any) => element.id === product_id);
        if(index === -1)
        {
            return;
        }

        Swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือน',
            text: `คุณยืนยันที่จะลบ ${props.listProducts[index].name} ใช่หรือไม่ หากคุณกดยืนยัน ข้อมูลของสินค้าชิ้นนี้ จะถูกลบหายออกไปทั้งหมด และ ไม่สามารถกู้คืนได้`,
            showCancelButton: true
        }).then(async (result) => {
            if(!result.isConfirmed)
            {
                return;
            }

            const token = cookies.get('access_token');
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/products/deleteProduct`, {
                product_id,
            }, {
                headers: {
                    Authorization: token
                }
            });

            if(data && data.message === 'OK')
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `ลบ ${props.listProducts[index].name} เรียบร้อยแล้ว`
                });

                return props.getProducts();
            }

            Swal.fire({
                icon: 'error',
                title: 'แจ้งเตือน',
                text: `ลบ ${props.listProducts[index].name} ไม่สำเร็จ (${data.message || "ไม่ทราบข้อผิดพลาด"})`
            });
        })
    }
    return (
        <AllProducts container >
            <Text40px>สินค้าทั้งหมด</Text40px>
            <Products container>
                {
                    props.listProducts.map((prod:any, index:number) => (
                        <Card sx={{ maxWidth: 345 }} key={index}>
                            <CardMedia
                                sx={{ height: 140 }}
                                image={prod.path.replace("{api_path}", process.env.REACT_APP_API_URL)}
                                title="product"
                            />
                            <CardContent>
                                <Text20px gutterBottom>
                                    {prod.name}
                                </Text20px>
                                {/* <Text16px gutterBottom>
                                    Size: {prod.size}
                                </Text16px> */}
                                {/* <Text20px gutterBottom>
                                    ฿{prod.price}
                                </Text20px> */}
                            </CardContent>
                            <CardActions>
                                <Button size="small" variant="contained" onClick={() => handleOpen(prod)}>แก้ไข</Button>
                                <Button size="small" variant="contained" onClick={() => handleRemove(prod.id)}>ลบ</Button>
                            </CardActions>
                        </Card>
                    ))
                }

            </Products>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container flexDirection={"column"} rowGap={"24px"} width={"70%"} margin={"0 auto"}>
                        <Img src={selectedFile === null ? product : URL.createObjectURL(selectedFile)}
                            sx={{ width: { xs: "80%", md: "50%" }, margin: "0 auto" }} />

                        <input type="file"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                if (!event.target.files) return
                                setSelectedFile(event.target.files[0]);
                            }} />
                        <TextField
                            required
                            label="ชื่อ"
                            defaultValue={(select as { name: string }).name}
                        />
                        <TextField
                            required
                            label="ขนาด"
                            defaultValue={(select as { size: string }).size}
                        />
                        <TextField
                            required
                            label="ราคา"
                            defaultValue={(select as { price: string }).price}
                        />
                        <Grid container justifyContent={"space-between"}>
                            <Button size="small" variant="contained">ยืนยัน</Button>
                            <Button size="small" variant="contained">ยกเลิก</Button>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </AllProducts>
    )
}

export default AllProduct