import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Pagination, TextField, Typography, styled } from '@mui/material'
import { useState, useEffect, useRef } from 'react';
import HeroImg from '../../layout/HeroImg';
import plastic from '../../assets/passticglass.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

type Props = {}

const Text16px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },
}));
const Text24px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px"
  },
  fontWeight: "700"
}));
const Text20px = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "16px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "20px"
  },
  fontWeight: "700"
}));
const ProductContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: "#F5F5F5",
  gap: "16px"
}));
const ProductContent = styled(Grid)(({ theme }) => ({
  justifyContent: "space-between",
}));
const LeftContent = styled(Grid)(({ theme }) => ({
  border: "1px solid #D9D9D9",
  padding: "10px",
  gap: "12px",
  width: "30%",
  backgroundColor: "#FFF"
}));
const RightContent = styled(Grid)(({ theme }) => ({
  width: "69%",
  backgroundColor: "#FFF",
  border: "1px solid #D9D9D9",
  flexDirection: "row",
  // gap: "1%",
  padding: "6px"
}));
const CheckBox = styled(FormControlLabel)(({ theme }) => ({
  "& .MuiTypography-root": {
    [theme.breakpoints.up("xs")]: {
      fontSize: "10px"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "12px"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px"
    },
  }
}));
const CardContent = styled(Grid)(({ theme }) => ({
  backgroundColor: "#FFF",
  border: "1px solid #D9D9D9",
  padding: "6px",
  flexDirection: "column",
  gap: "12px",
  [theme.breakpoints.up("xs")]: {
    width: "49%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "32%",
  },
  [theme.breakpoints.up("md")]: {
    width: "24%",
  },
  margin:"0.5%"
}));
const Img = styled("img")(({ theme }) => ({

}));
const BottonAddtoCart = styled(Button)(({ theme }) => ({
  backgroundColor: "#3892F8",
  border: "1px solid #3892F8",
  color: "#FFF",
  borderRadius: "6px",
  WebkitTapHighlightColor: "#FFF",
  '&:hover': {
    backgroundColor: "#FFF",
    color: "#3892F8"
  },
  [theme.breakpoints.up("xs")]: {
    fontSize: "10px"
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px"
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "14px"
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px"
  },
  padding: "6px"
}));
function Product_Catalog({ }: Props) {
  const loopcard = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const Navigate = useNavigate();
  const [allShape, setAllShape] = useState<any>([]);
  const [listProducts, setListProducts] = useState<any>([]);
  const [defaultListProducts, setDefaultListProducts] = useState<any>([]);
  const [allCategories, setAllCategories] = useState<any>([]);

  const [checkedCategories, setCheckedCategories] = useState<any>([-1]);
  const [checkedShape, setCheckedShape] = useState<any>([-1]);

  const minRef = useRef<any>(null);
  const maxRef = useRef<any>(null);

  useEffect(() => {
    reloadShape();
    reloadCateogries();
    reloadProducts();
  }, []);

  const reloadProducts = async () => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/products/all`);
    setListProducts(data.data);
    setDefaultListProducts(data.data);
  }

  const reloadShape = async () => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/products/getallshape`);
    setAllShape(data.data);
  }

  const reloadCateogries = async () => {
    const data = await axios.get(`${process.env.REACT_APP_API_URL}/categories/all`);
    setAllCategories(data.data);
  }

  const handleCheckedCategory = (category_id:number) => {
    let temp_categories = [];
    temp_categories = [
      ...checkedCategories
    ];

    const findIndexElement = temp_categories.findIndex((element:number) => element === category_id);
    if(findIndexElement === -1)
    {
      temp_categories.push(category_id);
    }
    else
    {
      temp_categories = temp_categories.filter((element) => element !== category_id)
    }

    setCheckedCategories(temp_categories);
  }

  const handleCheckedShape = (shape_id:number) => {
    let temp_shape = [];
    temp_shape = [
      ...checkedShape
    ];

    const findIndexElement = temp_shape.findIndex((element:number) => element === shape_id);
    if(findIndexElement === -1)
    {
      temp_shape.push(shape_id);
    }
    else
    {
      temp_shape = temp_shape.filter((element) => element !== shape_id)
    }

    setCheckedShape(temp_shape);
  }

  const handleChangePriceRange = () => {
    let temp = [];
    const temp_filter = defaultListProducts.filter((product:any) => {
      if(minRef.current.value !== undefined && maxRef.current.value !== undefined && minRef.current.value !== '' && maxRef.current.value !== '')
      {
        if(parseFloat(product.price) >= parseFloat(minRef.current.value) && parseFloat(product.price) <= parseFloat(maxRef.current.value))
        {
          return true;
        }
        
        return false;
      }

      return true;
    });

    temp = [
      ...temp_filter
    ]
    setListProducts(temp);
  }

  return (
    <ProductContainer container>
      <HeroImg />
      <ProductContent container>
        <LeftContent>
          <Text24px>
            Markets and Applications
          </Text24px>
          <FormGroup>
            <CheckBox control={<Checkbox defaultChecked />} label="All" onChange={() => handleCheckedCategory(-1)} />
            {
              allCategories.map((element:any, index:number) => {
                return (
                  <CheckBox control={<Checkbox />} label={element.name} key={index} onChange={() => handleCheckedCategory(element.id)} />
                )
              })
            }
          </FormGroup>
          <Text24px>
            Shape
          </Text24px>
          <FormGroup>
            <CheckBox control={<Checkbox defaultChecked />} label="All" onChange={() => handleCheckedShape(-1)} />
            {
              allShape.map((element:any, index:number) => {
                return (
                  <CheckBox control={<Checkbox />} label={element.name} key={index} onChange={() => handleCheckedShape(element.id)} />
                )
              })
            }
          </FormGroup>
          <Text24px>
            Overflow Volume
          </Text24px>
          <Text16px>Price</Text16px>
          <Grid container>
            <Grid item xs={6} p={1}>
              <TextField
                sx={{ "& .MuiOutlinedInput-input": { padding: "3px 5px" } }}
                fullWidth
                placeholder="Min"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={minRef}
              />
            </Grid>
            <Grid item xs={6} p={1}>
              <TextField
                sx={{ "& .MuiOutlinedInput-input": { padding: "3px 5px" } }}
                fullWidth
                type="number"
                placeholder="Max"
                InputLabelProps={{
                  shrink: true,
                }}
                inputRef={maxRef}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} p={1}>
            <Button sx={
              {
                backgroundColor: '#3892F8',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#2c73c4',
                  color: 'white'
                }
              }
            } fullWidth onClick={handleChangePriceRange}>
              ตกลง
            </Button>
          </Grid>
        </LeftContent>
        <RightContent container>
          {
            listProducts.filter((product:any) => {
              const findIndexElementAll = checkedCategories.findIndex((element:number) => element === -1);
              const findIndexElementShapeAll = checkedShape.findIndex((element:number) => element === -1);

              if(findIndexElementAll !== -1 && findIndexElementShapeAll !== -1)
              {
                return true;
              }
              else
              {
                const findIndexElement = checkedCategories.findIndex((element:number) => element === product.category_id);
                const findIndexElementShape = checkedShape.findIndex((element:number) => element === product.shape_id);

                if(findIndexElement !== -1 && findIndexElementShapeAll !== -1)
                {
                  return true;
                }
                else if(findIndexElementAll !== -1 && findIndexElementShape !== -1)
                {
                  return true;
                }
                else if(findIndexElement !== -1 && findIndexElementShape !== -1)
                {
                  return true;
                }

                return false;
              }
            }).map((product:any, index:number) => (
              <CardContent key={index} container>
                <Img src={product.path.replace('{api_path}', process.env.REACT_APP_API_URL)} sx={{ width: "100%", margin: "0 auto" }} />
                <Text20px>{product.name}</Text20px>
                <Text16px sx={{ color: "#B4B4B4" }}><span style={{ color: "#667479" }}>Size:</span> </Text16px>
                <Text24px sx={{ color: "#3892F8" }}>฿{
                  parseFloat(product.price).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }</Text24px>
                <Grid container>
                  <BottonAddtoCart onClick={()=> Navigate(`/product/${product.id}`)} variant="contained">Add to cart</BottonAddtoCart>
                </Grid> 
              </CardContent>
            ))
          }
          {/* <Grid container justifyContent={"center"} margin={"12px 0"}>
            <Pagination count={24} color="primary" />
          </Grid> */}
        </RightContent>
      </ProductContent>
    </ProductContainer>
  )
}

export default Product_Catalog